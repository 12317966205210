import { Auth } from "aws-amplify";
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { BaseCustomer } from "../models/API";
import { getCustomer } from "../services/user.service";
import { IToast } from "../models/toast.model";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { setUserAnalytics, triggerLoginAnalytics } from "../helpers/analytics.helper";

type Props = {
  children?: ReactNode;
  setToast: Dispatch<SetStateAction<IToast | null>>;
};

type AuthContextProps = {
  isLoading: boolean;
  isAuthorized: boolean;
  customer: BaseCustomer | null;
};

const AuthProvider = ({ children, setToast }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [customer, setCustomer] = useState<BaseCustomer | null>(null);

  //Effects
  useEffect(() => {
    initData();
  }, []);

  //Methods
  const initData = async () => {
    try {
      //Save url params before aws-amplify removes code params
      const urlParams = new URLSearchParams(window.location.search);

      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const authenticated = authenticatedUser !== undefined;
      setUserAnalytics(authenticatedUser?.attributes?.sub || "");
      if (authenticated) {
        if (urlParams.get("code")) {
          triggerLoginAnalytics();
        }
        setCustomer(await getCustomer(setToast));
      }
      setIsAuthorized(authenticated);
    } catch {
      setIsAuthorized(false);
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito
      });
    } finally {
      setIsLoading(false);
    }
  };

  return <AuthContext.Provider value={{ isLoading, isAuthorized, customer }}>{children}</AuthContext.Provider>;
};

export const AuthContext = createContext<AuthContextProps>({ isLoading: false, isAuthorized: false, customer: null });
export default AuthProvider;
