import dayjs from "dayjs";
import i18next from "i18next";
import { Phase, SampleResultCalculations, SampleResultTableItem } from "../models/API";
import { DsmTableData } from "@dsm-dcs/design-system/dist/types/types/table";
import { IResultListRow } from "../models/resultList.model";
import { IResultDonutGraphValue } from "../models/resultDonutGraph.model";
import { SampleListTableActions } from "../models/enums/actions.enum";
import { DsmColors } from "@dsm-dcs/design-system";

export const handleSamplesTableData = (
  data: SampleResultTableItem[],
  sampleCalculations: SampleResultCalculations | null,
  addLocation = false
): DsmTableData => {
  const tableData = {
    columns: [
      { id: "name", label: i18next.t("sample-request.name") },
      { id: "collectionDate", label: i18next.t("sample-request.collection-date") },
      { id: "phase", label: i18next.t("sample-request.phase") },
      { id: "average", label: i18next.t("sample-request.average") }
    ],
    data: data.map((_) => {
      return {
        isClickable: true,
        id: _.sampleRequestId || "",
        actions: [
          {
            type: SampleListTableActions.Results,
            icon: "charts/bar-chart-square-01",
            title: i18next.t("sample-request-list.table-actions.results")
          },
          {
            type: SampleListTableActions.Delete,
            icon: "general/trash-01",
            title: i18next.t("general.delete"),
            destructive: true,
            disabled: true
          }
        ],
        cells: [
          { headerText: _.name || "" },
          { value: dayjs(_.collectionDate).format(i18next.t("general.date-format")) },
          // { value: _.locationName || "" },
          { value: _.phaseName || "" },
          {
            badges: [
              {
                value: _.average && _.average > 0 ? `${_.average?.toString()} ng/ml` : i18next.t("general.result-status.pending"),
                variant: getAverageVariant(sampleCalculations?.selectedPhase, _.average)
              }
            ]
          }
        ]
      };
    })
  };
  if (addLocation) {
    tableData.columns.splice(2, 0, { id: "location", label: i18next.t("sample-request.farm") });
    data.forEach((_, i) => {
      tableData.data[i].cells.splice(2, 0, { value: _.locationName || "" });
    });
  }
  return tableData;
};

export const getAverageText = (phase: Phase | null | undefined, average: number | null | undefined): string => {
  if (!phase || !average) {
    return "";
  } else {
    if (average < (phase.deficient || 0)) {
      return "Deficient";
    } else if (average < (phase.insufficient || 0)) {
      return "Insufficient";
    } else if (average < (phase.adequate || 0)) {
      return "Adequate";
    } else {
      return "Optimum";
    }
  }
};

export const getAverageVariant = (phase: Phase | null | undefined, average: number | null | undefined): DsmColors => {
  if (!phase || !average) {
    return "primary";
  } else {
    if (average < (phase.deficient || 0)) {
      return "error";
    } else if (average < (phase.insufficient || 0)) {
      return "warning";
    } else if (average < (phase.adequate || 0)) {
      return "yellow";
    } else {
      return "success";
    }
  }
};

export const handleResultListData = (sampleCalculations: SampleResultCalculations): IResultListRow[] => {
  return [
    {
      type: "optimum",
      value: `${sampleCalculations.optimum || "0"}%`,
      title: sampleCalculations?.selectedPhase
        ? `>${sampleCalculations?.selectedPhase?.adequate} ${i18next.t("general.result-description.unit")}`
        : "",
      description: i18next.t("general.result-description.optimum")
    },
    {
      type: "adequate",
      value: `${sampleCalculations.adequate || "0"}%`,
      title: sampleCalculations?.selectedPhase
        ? `${sampleCalculations?.selectedPhase?.insufficient}-${sampleCalculations?.selectedPhase?.adequate} ${i18next.t(
            "general.result-description.unit"
          )}`
        : "",
      description: i18next.t("general.result-description.adequate")
    },
    {
      type: "insufficient",
      value: `${sampleCalculations.insufficient || "0"}%`,
      title: sampleCalculations?.selectedPhase
        ? `${sampleCalculations?.selectedPhase?.deficient}-${sampleCalculations?.selectedPhase?.insufficient} ${i18next.t(
            "general.result-description.unit"
          )}`
        : "",
      description: i18next.t("general.result-description.insufficient")
    },
    {
      type: "deficient",
      value: `${sampleCalculations.deficient || "0"}%`,
      title: sampleCalculations?.selectedPhase
        ? `<${sampleCalculations?.selectedPhase?.deficient} ${i18next.t("general.result-description.unit")}`
        : "",
      description: i18next.t("general.result-description.deficient")
    }
  ];
};

export const handleResultDonutGraphData = (sampleCalculations: SampleResultCalculations): IResultDonutGraphValue[] => {
  return [
    { type: "optimum", value: sampleCalculations.optimum || 0, label: i18next.t("general.result-breakdown.optimum"), unit: "%" },
    { type: "adequate", value: sampleCalculations.adequate || 0, label: i18next.t("general.result-breakdown.adequate"), unit: "%" },
    {
      type: "insufficient",
      value: sampleCalculations.insufficient || 0,
      label: i18next.t("general.result-breakdown.insufficient"),
      unit: "%"
    },
    { type: "deficient", value: sampleCalculations.deficient || 0, label: i18next.t("general.result-breakdown.deficient"), unit: "%" }
  ];
};

export const getScatterCss = (phase: Phase | null | undefined, resultValues: number[]): string => {
  if (!phase) {
    return "";
  }

  const max = Math.max(...resultValues.concat([phase?.optimum || 0]));
  const deficient = phase.deficient ? 100 - (100 / max) * phase.deficient : 0;
  const insufficient = phase.insufficient ? 100 - (100 / max) * phase.insufficient : 0;
  const adequate = phase.adequate ? 100 - (100 / max) * phase.adequate : insufficient;

  return `.scatter { &::before { background: linear-gradient(to bottom, var(--dsm-color-success-lightest) 0%, var(--dsm-color-success-lightest) ${adequate}%, var(--dsm-color-yellow-lightest) ${adequate}%, var(--dsm-color-yellow-lightest) ${insufficient}%, var(--dsm-color-warning-lightest) ${insufficient}%, var(--dsm-color-warning-lightest) ${deficient}%, var(--dsm-color-error-lightest) ${deficient}%, var(--dsm-color-error-lightest) 100%); } }`;
};
