import { DsmEmptyState, DsmGrid, DsmLabel, DsmLoadingIndicator, DsmPagination, DsmTable, DsmTabs } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useContext, useEffect, useState } from "react";
import { useLayout } from "../../../contexts/layout.context";
import styles from "./Dashboard.module.scss";
import { BasePhase, Page, SampleResultCalculations, SampleResultTableInput, SortDirection } from "../../../models/API";
import {
  getAverageText,
  getAverageVariant,
  handleResultDonutGraphData,
  handleResultListData,
  handleSamplesTableData
} from "../../../helpers/sampleData.helper";
import ResultList from "../../../components/resultList/ResultList";
import { IResultListRow } from "../../../models/resultList.model";
import ResultDonutGraph from "../../../components/resultDonutGraph/ResultDonutGraph";
import { IResultDonutGraphValue } from "../../../models/resultDonutGraph.model";
import { getSampleResultCalculationsForCustomer, getSampleResultsForCustomer } from "../../../services/sample.service";
import { DsmTableData, RowActionClickEvent } from "@dsm-dcs/design-system/dist/types/types/table";
import { DsmTableCustomEvent } from "@dsm-dcs/design-system";
import { routeTypes, routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { SampleListTableActions } from "../../../models/enums/actions.enum";
import { AuthContext } from "../../../contexts/auth.context";

function Dashboard() {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer } = useContext(AuthContext);

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForPhase, setIsLoadingForPhase] = useState<boolean>(false);
  const [availablePhases, setAvailablePhases] = useState<BasePhase[]>([]);
  const [currentPhaseId, setCurrentPhaseId] = useState<string | null>(null);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);
  const [sampleCalculations, setSampleCalculations] = useState<SampleResultCalculations | null>(null);
  const [resultList, setResultList] = useState<IResultListRow[]>([]);
  const [resultDonutGraph, setResultDonutGraph] = useState<IResultDonutGraphValue[]>([]);
  const [samplesTable, setSamplesTable] = useState<DsmTableData>();
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);

  //Effects
  useEffect(() => {
    setPageTitle(t("dashboard.page.title"));
    setCrumbs([{ title: t("dashboard.page.title"), type: routeTypes.dashboard }]);
    initData();
  }, []);

  //Methods
  const initData = async () => {
    await getDataForPhase(customer?.id || "", null, true);
    setIsLoading(false);
  };

  const getDataForPhase = async (customerId: string, phaseId: string | null, initial: boolean) => {
    const loadSampleCalculationsForPhase = getSampleResultCalculationsForCustomer(customerId, phaseId, setToast);

    const sampleResultTableInput: SampleResultTableInput = { itemsPerPage: 10, sortDirection: SortDirection.Desc, phaseId };
    const loadSamplesForPhase = getSampleResultsForCustomer(customerId, sampleResultTableInput, setToast);

    await Promise.all([loadSampleCalculationsForPhase, loadSamplesForPhase]).then(async (data) => {
      if (initial) {
        //Only set available phases on first load
        setAvailablePhases(data[0]?.availablePhases || []);
      }
      setResultList(data[0] ? handleResultListData(data[0]) : []);
      setResultDonutGraph(data[0] ? handleResultDonutGraphData(data[0]) : []);
      setSampleCalculations(data[0]);
      setSamplesTable(handleSamplesTableData(data[1]?.rows || [], data[0] || null, true));
      setSamplesTablePages(data[1]?.pages || []);
    });
  };

  const handlePhaseChange = async (phaseId: string | null) => {
    setIsLoadingForPhase(true);
    setCurrentSamplesTablePage(1);
    setCurrentPhaseId(phaseId);
    await getDataForPhase(customer?.id || "", phaseId, false);
    setIsLoadingForPhase(false);
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
    const pageData = samplesTablePages.find((_) => _.page === page);

    const sampleResultTableInput: SampleResultTableInput = {
      itemsPerPage: 10,
      sortDirection: SortDirection.Desc,
      phaseId: currentPhaseId,
      pageToken: pageData?.token || ""
    };
    const samplesData = await getSampleResultsForCustomer(customer?.id || "", sampleResultTableInput, setToast);
    setSamplesTable(handleSamplesTableData(samplesData?.rows || [], sampleCalculations));
  };

  const handleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  const handleTableAction = (e: DsmTableCustomEvent<RowActionClickEvent>) => {
    if (e.detail.action === SampleListTableActions.Results) {
      navigate(routes.farmSample.replace(":sampleID", e.detail.id));
    }
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader header={t("dashboard.page.title")} description={t("dashboard.page.description")}></PageHeader>
      {!isLoading ? (
        <>
          {availablePhases.length > 1 ? (
            <DsmTabs className={styles["phase-filter"]} data-testid="phase-filter">
              <a slot="navigation" className={`${currentPhaseId === null ? "active" : ""}`} onClick={() => handlePhaseChange(null)}>
                {t("general.all")}
              </a>
              {availablePhases?.map((phase) => (
                <a
                  slot="navigation"
                  className={`${currentPhaseId === phase.id ? "active" : ""}`}
                  onClick={() => handlePhaseChange(phase.id || null)}
                  key={phase.id}
                >
                  {phase.name}
                </a>
              ))}
            </DsmTabs>
          ) : null}
          {!isLoadingForPhase ? (
            <>
              {sampleCalculations?.totalAverage && sampleCalculations.totalAverage > 0 ? (
                <div className={styles.results}>
                  <div className={`card ${styles["results-overview"]}`} data-testid="results-overview">
                    <h5 className="card__header">{t("dashboard.result-overview.title")}</h5>
                    <ResultList data={resultList}></ResultList>
                  </div>
                  <div className={styles.results__side}>
                    <div className={`card ${styles["results-average"]}`} data-testid="results-average">
                      <h5 className="card__header">{t("dashboard.result-average.title")}</h5>
                      <div className={styles["results-average__average"]}>
                        <span>
                          {t("dashboard.result-average.value", {
                            value: sampleCalculations.totalAverage || "0"
                          })}
                        </span>
                        {sampleCalculations.selectedPhase ? (
                          <DsmLabel variant={getAverageVariant(sampleCalculations.selectedPhase, sampleCalculations.totalAverage)}>
                            {getAverageText(sampleCalculations?.selectedPhase, sampleCalculations.totalAverage)}
                          </DsmLabel>
                        ) : null}
                      </div>
                    </div>
                    <div className={`card ${styles["results-breakdown"]}`} data-testid="results-breakdown">
                      <h5 className="card__header">{t("dashboard.result-breakdown.title")}</h5>
                      <ResultDonutGraph data={resultDonutGraph}></ResultDonutGraph>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={`card card--table ${styles["samples-table"]}`} data-testid="samples-table">
                {samplesTable?.data.length ? (
                  <>
                    <DsmTable data={samplesTable} onDsmRowClick={handleTableRowClick} onDsmRowActionClick={handleTableAction}></DsmTable>
                    {samplesTablePages.length > 0 ? (
                      <DsmPagination
                        currentPage={currentSamplesTablePage}
                        pageCount={samplesTablePages.length + 1}
                        onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                      ></DsmPagination>
                    ) : null}
                  </>
                ) : (
                  <DsmEmptyState icon="charts/pie-chart-01" header={t("dashboard.samples-table.empty")}></DsmEmptyState>
                )}
              </div>
            </>
          ) : (
            <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
          )}
        </>
      ) : (
        <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
      )}
    </DsmGrid>
  );
}

export default Dashboard;
