import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { DsmSelectOption } from "@dsm-dcs/design-system";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import {
  BaseSpecies,
  Country,
  GetCountriesQuery,
  GetPhaseForSpeciesQuery,
  GetPhaseForSpeciesQueryVariables,
  GetSpeciesQuery,
  GetSpeciesQueryVariables,
  Phase,
  SpeciesGroup
} from "../models/API";
import { BaseQuery } from "../graphql/interfaces";
import { Dispatch, SetStateAction } from "react";
import { IToast } from "../models/toast.model";

export const getCountries = async (setToast: Dispatch<SetStateAction<IToast | null>>): Promise<Country[]> => {
  try {
    const request = { query: queries.getCountries };
    const result = await API.graphql<GraphQLQuery<GetCountriesQuery>>(request);
    return result.data?.getCountries.countries || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading countries", error: errors[0].message });
    return [];
  }
};

export const getCountriesForSelect = async (setToast: Dispatch<SetStateAction<IToast | null>>) => {
  const countries = await getCountries(setToast);
  const countryOptions: DsmSelectOption[] = countries.map((country) => {
    return {
      text: country.name || "",
      value: country.id || ""
    };
  });
  countryOptions.sort((a, b) => a.text.localeCompare(b.text));
  return countryOptions;
};

export const getSpecies = async (species: SpeciesGroup, setToast: Dispatch<SetStateAction<IToast | null>>): Promise<BaseSpecies[]> => {
  try {
    const request: BaseQuery<GetSpeciesQueryVariables> = {
      query: queries.getSpecies,
      variables: {
        group: species
      }
    };
    const result = await API.graphql<GraphQLQuery<GetSpeciesQuery>>(request);
    return result.data?.getSpecies?.species || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading species", error: errors[0].message });
    return [];
  }
};

export const getSpeciesForSelect = async (
  speciesGroup: SpeciesGroup,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<DsmSelectOption[]> => {
  const species = await getSpecies(speciesGroup, setToast);

  const options =
    species.map((s) => {
      return { value: s.id || "", text: s.name || "" };
    }) || [];
  options.sort((a, b) => a.text.localeCompare(b.text));
  return options;
};

export const getPhase = async (speciesId: string, setToast: Dispatch<SetStateAction<IToast | null>>): Promise<Phase[]> => {
  try {
    const request: BaseQuery<GetPhaseForSpeciesQueryVariables> = {
      query: queries.getPhaseForSpecies,
      variables: {
        speciesId: speciesId
      }
    };
    const result = await API.graphql<GraphQLQuery<GetPhaseForSpeciesQuery>>(request);
    return result.data?.getPhaseForSpecies?.phases || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading phases", error: errors[0].message });
    return [];
  }
};

export const getPhaseForSelect = async (
  speciesId: string,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<DsmSelectOption[]> => {
  const phase = await getPhase(speciesId, setToast);

  const options =
    phase.map((p) => {
      return { value: p.id || "", text: p.name || "" };
    }) || [];
  options.sort((a, b) => a.text.localeCompare(b.text));
  return options;
};

export const convertPhasesForSelect = (phases: Phase[]): DsmSelectOption[] => {
  const options =
    phases.map((p) => {
      return { value: p.id || "", text: p.name || "" };
    }) || [];
  options.sort((a, b) => a.text.localeCompare(b.text));
  return options;
};
