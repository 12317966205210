import { DsmEmptyState, DsmGrid, DsmLabel, DsmLoadingIndicator, DsmPagination, DsmTable, DsmTabs } from "@dsm-dcs/design-system-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSampleResultCalculationsForLocation, getSampleResultsForLocation } from "../../../services/sample.service";
import { DsmTableData, RowActionClickEvent } from "@dsm-dcs/design-system/dist/types/types/table";
import { routeTypes, routes } from "../../../routes";
import { DsmTableCustomEvent } from "@dsm-dcs/design-system";
import { getLocation } from "../../../services/location.service";
import { BasePhase, Location, Page, SampleResultCalculations, SampleResultTableInput } from "../../../models/API";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import ResultList from "../../../components/resultList/ResultList";
import { IResultListRow } from "../../../models/resultList.model";
import { IResultDonutGraphValue } from "../../../models/resultDonutGraph.model";
import ResultDonutGraph from "../../../components/resultDonutGraph/ResultDonutGraph";
import styles from "./SampleRequestList.module.scss";
import { useLayout } from "../../../contexts/layout.context";
import {
  getAverageText,
  getAverageVariant,
  handleResultDonutGraphData,
  handleResultListData,
  handleSamplesTableData
} from "../../../helpers/sampleData.helper";
import { SampleListTableActions } from "../../../models/enums/actions.enum";

function SampleRequestList() {
  //Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { locationId } = useParams();

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForPhase, setIsLoadingForPhase] = useState<boolean>(false);
  const [location, setLocation] = useState<Location | null>(null);
  const [availablePhases, setAvailablePhases] = useState<BasePhase[]>([]);
  const [currentPhaseId, setCurrentPhaseId] = useState<string | null>(null);
  const [currentSamplesTablePage, setCurrentSamplesTablePage] = useState<number>(1);
  const [sampleCalculations, setSampleCalculations] = useState<SampleResultCalculations | null>(null);
  const [resultList, setResultList] = useState<IResultListRow[]>([]);
  const [resultDonutGraph, setResultDonutGraph] = useState<IResultDonutGraphValue[]>([]);
  const [samplesTable, setSamplesTable] = useState<DsmTableData>();
  const [samplesTablePages, setSamplesTablePages] = useState<Page[]>([]);

  //Effect
  useEffect(() => {
    setPageTitle("");
    setCrumbs([
      { title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms },
      { title: "...", type: routeTypes.loading }
    ]);
    initData();
  }, []);

  useEffect(() => {
    if (!location) {
      return;
    }
    setPageTitle(location?.name || "");
    setCrumbs([
      { title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms },
      { title: location?.name || "", type: routeTypes.farmSamples }
    ]);
  }, [location]);

  //Methods
  const initData = async () => {
    const loadLocation = getLocation(locationId || "", setToast);
    const loadDataForPhase = getDataForPhase(null, true);

    await Promise.all([loadLocation, loadDataForPhase]).then(async (data) => {
      setLocation(data[0]);
    });
    setIsLoading(false);
  };

  const getDataForPhase = async (phaseId: string | null, initial: boolean) => {
    const loadSampleCalculationsForPhase = getSampleResultCalculationsForLocation(locationId || "", phaseId, setToast);

    const sampleResultTableInput: SampleResultTableInput = {
      itemsPerPage: 10,
      phaseId
    };
    const loadSamplesForPhase = getSampleResultsForLocation(locationId || "", sampleResultTableInput, setToast);

    await Promise.all([loadSampleCalculationsForPhase, loadSamplesForPhase]).then(async (data) => {
      if (initial) {
        //Only set available phases on first load
        setAvailablePhases(data[0]?.availablePhases || []);
      }
      setResultList(data[0] ? handleResultListData(data[0]) : []);
      setResultDonutGraph(data[0] ? handleResultDonutGraphData(data[0]) : []);
      setSampleCalculations(data[0]);
      setSamplesTable(handleSamplesTableData(data[1]?.rows || [], data[0] || null));
      setSamplesTablePages(data[1]?.pages || []);
    });
  };

  const handlePhaseChange = async (phaseId: string | null) => {
    setIsLoadingForPhase(true);
    setCurrentSamplesTablePage(1);
    setCurrentPhaseId(phaseId);
    await getDataForPhase(phaseId, false);
    setIsLoadingForPhase(false);
  };

  const handleResultListPageChange = async (page: number) => {
    setCurrentSamplesTablePage(page);
    const pageData = samplesTablePages.find((_) => _.page === page);

    const sampleResultTableInput: SampleResultTableInput = {
      itemsPerPage: 10,
      phaseId: currentPhaseId,
      pageToken: pageData?.token || null
    };
    const samplesData = await getSampleResultsForLocation(locationId || "", sampleResultTableInput, setToast);
    setSamplesTable(handleSamplesTableData(samplesData?.rows || [], sampleCalculations));
  };

  const handleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  const handleTableAction = (e: DsmTableCustomEvent<RowActionClickEvent>) => {
    if (e.detail.action === SampleListTableActions.Results) {
      navigate(routes.farmSample.replace(":sampleID", e.detail.id));
    }
  };

  return (
    <>
      <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
        {!isLoading ? (
          <>
            <PageHeader header={location?.name || ""} description={t("sample-request-list.page.description")}></PageHeader>
            <div className="sub-header">
              <h3>{t("sample-request-list.results-header")}</h3>
            </div>
            {availablePhases.length > 0 ? (
              <DsmTabs className={styles["phase-filter"]}>
                <a slot="navigation" className={`${currentPhaseId === null ? "active" : ""}`} onClick={() => handlePhaseChange(null)}>
                  {t("general.all")}
                </a>
                {availablePhases?.map((phase) => (
                  <a
                    slot="navigation"
                    className={`${currentPhaseId === phase.id ? "active" : ""}`}
                    onClick={() => handlePhaseChange(phase.id || null)}
                    key={phase.id}
                  >
                    {phase.name}
                  </a>
                ))}
              </DsmTabs>
            ) : null}
            {!isLoadingForPhase ? (
              <>
                {sampleCalculations?.totalAverage && sampleCalculations.totalAverage > 0 ? (
                  <div className={styles.results}>
                    <div className={`card ${styles["results-overview"]}`}>
                      <h5 className="card__header">{t("sample-request-list.result-overview.title")}</h5>
                      <ResultList data={resultList}></ResultList>
                    </div>
                    <div className={styles.results__side}>
                      <div className={`card ${styles["results-average"]}`}>
                        <h5 className="card__header">{t("sample-request-list.result-average.title")}</h5>
                        <div className={styles["results-average__average"]}>
                          <span>{t("sample-request-list.result-average.value", { value: sampleCalculations.totalAverage || "0" })}</span>
                          {sampleCalculations.selectedPhase ? (
                            <DsmLabel variant={getAverageVariant(sampleCalculations?.selectedPhase, sampleCalculations.totalAverage)}>
                              {getAverageText(sampleCalculations?.selectedPhase, sampleCalculations.totalAverage)}
                            </DsmLabel>
                          ) : null}
                        </div>
                      </div>
                      <div className={`card ${styles["results-breakdown"]}`}>
                        <h5 className="card__header">{t("sample-request-list.result-breakdown.title")}</h5>
                        <ResultDonutGraph data={resultDonutGraph}></ResultDonutGraph>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={`card card--table ${styles["samples-table"]}`}>
                  {samplesTable?.data.length ? (
                    <>
                      <DsmTable data={samplesTable} onDsmRowClick={handleTableRowClick} onDsmRowActionClick={handleTableAction}></DsmTable>
                      {samplesTablePages.length > 0 ? (
                        <DsmPagination
                          currentPage={currentSamplesTablePage}
                          pageCount={samplesTablePages.length + 1}
                          onDsmChangePage={(e) => handleResultListPageChange(e.detail)}
                        ></DsmPagination>
                      ) : null}
                    </>
                  ) : (
                    <DsmEmptyState icon="charts/pie-chart-01" header={t("sample-request-list.samples-table.empty")}></DsmEmptyState>
                  )}
                </div>
              </>
            ) : (
              <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
            )}
          </>
        ) : null}
      </DsmGrid>
      {isLoading ? <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator> : null}
    </>
  );
}

export default SampleRequestList;
