export enum FarmListTableActions {
  Results = "results",
  Edit = "edit",
  Delete = "Delete"
}

export enum SampleListTableActions {
  Results = "results",
  Delete = "Delete"
}
