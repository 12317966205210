import Plot from "react-plotly.js";
import styles from "./ResultDonutGraph.module.scss";
import { IResultDonutGraphValue } from "../../models/resultDonutGraph.model";
import { useEffect, useState } from "react";
import { toFixedString } from "../../helpers/number.helper";

type Props = {
  data: IResultDonutGraphValue[];
};

function ResultDonutGraph({ data }: Props) {
  const [values, setValues] = useState<IResultDonutGraphValue[]>([]);

  useEffect(() => {
    setValues(data);
  }, [data]);

  const getColorForType = (type: string) => {
    switch (type) {
      case "optimum":
        return "#45ac34";
      case "adequate":
        return "#fcd34d";
      case "insufficient":
        return "#ee7203";
      case "deficient":
        return "#e51f22";
    }
  };

  return (
    <div className={styles.container}>
      <Plot
        data={[
          {
            marker: {
              colors: values.map((_) => getColorForType(_.type))
            },
            values: values.map((_) => _.value),
            hole: 0.6,
            textinfo: "none",
            hoverinfo: "none",
            sort: false,
            type: "pie",
            direction: "clockwise"
          }
        ]}
        layout={{
          width: 200,
          height: 200,
          showlegend: false,
          margin: { l: 0, r: 0, t: 0, b: 0 },
          font: {
            family: '"DM Sans", sans-serif'
          }
        }}
        config={{ staticPlot: true }}
      />
      <div className={styles.legend}>
        {values.map((row) => (
          <div className={`${styles["legend__row"]} ${styles[`legend__row--${row.type}`]}`} key={row.label}>{`${row.label} (${toFixedString(
            row.value,
            1
          )}${row.unit})`}</div>
        ))}
      </div>
    </div>
  );
}

export default ResultDonutGraph;
