import {
  DsmAlert,
  DsmGrid,
  DsmLabel,
  DsmLoadingIndicator,
  DsmButton,
  DsmButtonGroup,
  DsmButtonGroupItem,
  DsmTable
} from "@dsm-dcs/design-system-react";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ResultList from "../../../components/resultList/ResultList";
import { IResultListRow } from "../../../models/resultList.model";
import styles from "./SampleRequestRead.module.scss";
import ResultDonutGraph from "../../../components/resultDonutGraph/ResultDonutGraph";
import { IResultDonutGraphValue } from "../../../models/resultDonutGraph.model";
import Plot from "react-plotly.js";
import { useParams } from "react-router-dom";
import { getSampleResult } from "../../../services/sample.service";
import { SampleRequest } from "../../../models/API";
import { toFixedNumber } from "../../../helpers/number.helper";
import dayjs from "dayjs";
import { useLayout } from "../../../contexts/layout.context";
import { useNavigate } from "react-router-dom";
import { routeTypes, routes } from "../../../routes";
import { DsmTableData } from "@dsm-dcs/design-system";
import { getAverageText, getAverageVariant, getScatterCss } from "../../../helpers/sampleData.helper";
import useDetectPrint from "../../../hooks/print.hook";
import { ProblemArea, SpecificPhase, SpecificProblemArea } from "../../../models/testReason.model";
import { ProductionSystemSwine } from "../../../models/enums/productionSystem.enum";
import { DsmCDNUrl } from "../../../config";

function SampleRequestRead() {
  //Hooks
  const { sampleID } = useParams();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPrinting = useDetectPrint();

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sampleRequest, setSampleRequest] = useState<SampleRequest | null>();
  const [problem, setProblem] = useState<string>("other");
  const [resultList, setResultList] = useState<IResultListRow[]>([]);
  const [resultDonutGraph, setResultDonutGraph] = useState<IResultDonutGraphValue[]>([]);
  const [resultValues, setResultValues] = useState<number[]>([]);
  const [breakdownValues, setBreakdownValues] = useState<number[]>([]);
  const [shouldShowChart, setShouldShowChart] = useState<boolean>(true);
  const [samplesTable, setSamplesTable] = useState<DsmTableData>();
  const [scatterCSS, setScatterCSS] = useState<string>("");
  const [shouldShowLevels, setShouldShowLevels] = useState<boolean>(true);

  //Effects
  useEffect(() => {
    setPageTitle("");
    setCrumbs([
      { title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms },
      { title: "...", type: routeTypes.loading },
      { title: "...", type: routeTypes.loading }
    ]);
    getInitData();
  }, []);

  useEffect(() => {
    if (!sampleRequest) {
      return;
    }

    setPageTitle(sampleRequest.name || "");
    setCrumbs([
      { title: t("farms.page.title"), url: routes.farms, type: routeTypes.farms },
      {
        title: sampleRequest.location?.name || "",
        url: routes.farmSamples.replace(":locationId", sampleRequest.location?.id || ""),
        type: routeTypes.farmSamples
      },
      { title: sampleRequest.name || "", type: routeTypes.farmSample }
    ]);
    const showLevels = !!sampleRequest.phase?.adequate || !!sampleRequest.phase?.insufficient || !!sampleRequest.phase?.deficient;
    const results = sampleRequest.samples?.map((_) => _.results?.[0]?.result || 0) || [];
    setResultValues(results);
    setScatterCSS(showLevels ? getScatterCss(sampleRequest?.phase, results) : "");
    setShouldShowLevels(showLevels);
  }, [sampleRequest]);

  useEffect(() => {
    if (!resultValues.length) {
      return;
    }

    setBreakdownValues(calculateBreakdown(resultValues));
  }, [resultValues]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isPrinting]);

  useEffect(() => {
    if (!breakdownValues.length) {
      return;
    }

    const resultList: IResultListRow[] = [];
    if (sampleRequest?.phase?.optimum) {
      resultList.push({
        type: "optimum",
        value: `${breakdownValues[3]}%`,
        title: `${t("general.result-breakdown.optimum")}: >${sampleRequest?.phase?.adequate ?? sampleRequest?.phase?.insufficient} ${t(
          "general.result-description.unit"
        )}`,
        description:
          t("general.test-reason-result-description.optimum", { percentage: breakdownValues[3] }) +
          t(`general.test-reason-result-optimum.${problem}`)
      });
    }
    if (sampleRequest?.phase?.adequate) {
      resultList.push({
        type: "adequate",
        value: `${breakdownValues[2]}%`,
        title: `${t("general.result-breakdown.adequate")}: ${sampleRequest?.phase?.insufficient}-${sampleRequest?.phase?.adequate} ${t(
          "general.result-description.unit"
        )}`,
        description:
          t("general.test-reason-result-description.adequate", { percentage: breakdownValues[2] }) +
          t(`general.test-reason-result-adequate.${problem}`)
      });
    }
    if (sampleRequest?.phase?.insufficient) {
      resultList.push({
        type: "insufficient",
        value: `${breakdownValues[1]}%`,
        title: `${t("general.result-breakdown.insufficient")}: ${sampleRequest?.phase?.deficient}-${sampleRequest?.phase?.insufficient} ${t(
          "general.result-description.unit"
        )}`,
        description:
          t("general.test-reason-result-description.insufficient", { percentage: breakdownValues[1] }) +
          t(`general.test-reason-result-insufficient.${problem}`)
      });
    }

    if (sampleRequest?.phase?.deficient) {
      resultList.push({
        type: "deficient",
        value: `${breakdownValues[0]}%`,
        title: `${t("general.result-breakdown.deficient")}: <${sampleRequest?.phase?.deficient} ${t("general.result-description.unit")}`,
        description:
          t("general.test-reason-result-description.deficient", { percentage: breakdownValues[0] }) +
          t(`general.test-reason-result-deficient.${problem}`)
      });
    }
    setResultList(resultList);

    const resultDonutGraph: IResultDonutGraphValue[] = [];
    if (sampleRequest?.phase?.optimum) {
      resultDonutGraph.push({ type: "optimum", value: breakdownValues[3], label: t("general.result-breakdown.optimum"), unit: "%" });
    }
    if (sampleRequest?.phase?.adequate) {
      resultDonutGraph.push({ type: "adequate", value: breakdownValues[2], label: t("general.result-breakdown.adequate"), unit: "%" });
    }
    if (sampleRequest?.phase?.insufficient) {
      resultDonutGraph.push({
        type: "insufficient",
        value: breakdownValues[1],
        label: t("general.result-breakdown.insufficient"),
        unit: "%"
      });
    }
    if (sampleRequest?.phase?.deficient) {
      resultDonutGraph.push({ type: "deficient", value: breakdownValues[0], label: t("general.result-breakdown.deficient"), unit: "%" });
    }
    setResultDonutGraph(resultDonutGraph);
  }, [breakdownValues]);

  //Methods
  const isResultsPending = (): boolean => {
    return !sampleRequest?.average && sampleRequest?.average === 0;
  };

  const getInitData = async () => {
    const sampleRequest = await getSampleResult(sampleID || "", setToast);
    const tableData: DsmTableData = {
      columns: [
        { id: "result", label: t("sample-request-read.individual-results-table.result") },
        { id: "animal", label: t("sample-request.animal") }
      ],
      data:
        sampleRequest?.samples?.map((_) => {
          return {
            id: _.id || "",
            cells: [{ headerText: `${_.results?.[0]?.result || "0"} ng/ml` }, { value: _.animalId || "" }]
          };
        }) || []
    };

    if (shouldShowLevels) {
      tableData.columns.splice(1, 0, {
        id: "performance",
        label: t("sample-request-read.individual-results-table.performance")
      });

      sampleRequest?.samples?.forEach((_, i) => {
        tableData.data[i].cells.splice(1, 0, {
          badges: [
            {
              value: getAverageText(sampleRequest.phase, _.results?.[0]?.result),
              variant: getAverageVariant(sampleRequest.phase, _.results?.[0]?.result)
            }
          ]
        });
      });
    }

    const shouldShowParity = sampleRequest?.samples?.some((_) => _.parity);
    if (shouldShowParity) {
      tableData.columns.push({ id: "parity", label: t("sample-request.parity") });
      sampleRequest?.samples?.forEach((_, i) => {
        tableData.data[i].cells.push({ value: _.parity || "" });
      });
    }

    const shouldShowProblem = sampleRequest?.samples?.some((_) => _.problemAnswer);
    if (shouldShowProblem) {
      tableData.columns.push({
        id: "problemAnswer",
        label: t(`general.test-reason-problem.${sampleRequest?.problem?.toLocaleLowerCase()}`)
      });
      sampleRequest?.samples?.forEach((_, i) => {
        tableData.data[i].cells.push({ value: _.problemAnswer === true ? "Yes" : _.problemAnswer === false ? "No" : "-" });
      });
    }

    setProblem(sampleRequest?.problem?.toLocaleLowerCase() ?? "other");
    setSampleRequest(sampleRequest);
    setSamplesTable(tableData);
    setIsLoading(false);
  };

  const getMarkerColorForValue = (value: number): string => {
    if (value < (sampleRequest?.phase?.deficient || 0)) {
      return "#e51f22";
    } else if (value < (sampleRequest?.phase?.insufficient || 0)) {
      return "#ee7203";
    } else if (sampleRequest?.phase?.adequate && value < (sampleRequest?.phase?.adequate || 0)) {
      return "#fcd34d";
    } else {
      return "#45ac34";
    }
  };

  const calculateBreakdown = (resultValuesData: number[]) => {
    let deficient = 0;
    let insufficient = 0;
    let adequate = 0;
    let optimum = 0;
    resultValuesData.forEach((value) => {
      if (value < (sampleRequest?.phase?.deficient || 0)) {
        deficient++;
      } else if (value < (sampleRequest?.phase?.insufficient || 0)) {
        insufficient++;
      } else if (value < (sampleRequest?.phase?.adequate || 0)) {
        adequate++;
      } else {
        optimum++;
      }
    });
    const sampleAmount = sampleRequest?.samples?.length || 0;
    return [
      toFixedNumber((deficient / sampleAmount) * 100, 1),
      toFixedNumber((insufficient / sampleAmount) * 100, 1),
      toFixedNumber((adequate / sampleAmount) * 100, 1),
      toFixedNumber((optimum / sampleAmount) * 100, 1)
    ];
  };

  return (
    <>
      <style>{scatterCSS}</style>
      <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
        <PageHeader
          header={t("sample-request-read.page.title", { name: sampleRequest?.name || "" })}
          description={t("sample-request-read.page.description")}
          actionsSide={
            !isLoading && !isResultsPending() ? <DsmButton onClick={() => window.print()}>{t("general.print-report")}</DsmButton> : null
          }
        ></PageHeader>
        {!isLoading && sampleRequest ? (
          <>
            <div className="sub-header">
              <h3>{t("sample-request-read.analysis-info")}</h3>
            </div>
            <div className={styles["data"]}>
              <div className={styles.data__cell}>
                <b>{t("sample-request.farm")}</b>
                <DsmButton
                  variant="text"
                  onClick={() => {
                    navigate(routes.farmSamples.replace(":locationId", sampleRequest?.location?.id || ""));
                  }}
                >
                  {sampleRequest.location?.name}
                </DsmButton>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.barn")}</b>
                <span>{sampleRequest.barn || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.sample-amount")}</b>
                <span>{sampleRequest.samples?.length || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.taken-by")}</b>
                <span>{sampleRequest.takenBy || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.collection-date")}</b>
                <span>{dayjs(sampleRequest.collectionDate).format(t("general.date-format"))}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.phase")}</b>
                <span>{sampleRequest.phaseName || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.specific-phase")}</b>
                <span>
                  {sampleRequest.specificPhase && SpecificPhase[sampleRequest.specificPhase as keyof typeof SpecificPhase]
                    ? t(`general.specific-phase.${sampleRequest.specificPhase.toLocaleLowerCase()}`)
                    : sampleRequest.specificPhase || "-"}
                </span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.problem-area")}</b>
                <span>
                  {sampleRequest.problemArea && ProblemArea[sampleRequest.problemArea as keyof typeof ProblemArea]
                    ? t(`general.problem-area.${sampleRequest.problemArea.toLocaleLowerCase()}`)
                    : sampleRequest.problemArea || "-"}
                </span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.specific-problem-area")}</b>
                <span>
                  {sampleRequest.specificProblemArea &&
                  SpecificProblemArea[sampleRequest.specificProblemArea as keyof typeof SpecificProblemArea]
                    ? t(`general.specific-problem-area.${sampleRequest.specificProblemArea.toLocaleLowerCase()}`)
                    : sampleRequest.specificProblemArea || "-"}
                </span>
              </div>
              {sampleRequest.samples?.[0]?.geneticSupplier && sampleRequest.samples?.[0]?.geneticSupplier !== "Other" ? (
                <div className={styles.data__cell}>
                  <b>{t("sample-request.genetic-supplier")}</b>
                  <span>{sampleRequest.samples?.[0]?.geneticSupplier || "-"}</span>
                </div>
              ) : null}
              {sampleRequest.samples?.[0]?.geneticLineage && sampleRequest.samples?.[0]?.geneticLineage !== "Other" ? (
                <div className={styles.data__cell}>
                  <b>{t("sample-request.genetic-lineage")}</b>
                  <span>{sampleRequest.samples?.[0]?.geneticLineage || "-"}</span>
                </div>
              ) : null}
              <div className={styles.data__cell}>
                <b>{t("sample-request.housing")}</b>
                <span>{sampleRequest.housingSystem || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.production")}</b>
                <span>
                  {sampleRequest.productionSystem &&
                  ProductionSystemSwine[sampleRequest.productionSystem as keyof typeof ProductionSystemSwine]
                    ? t(`general.production-system-swine.${sampleRequest.productionSystem.toLocaleLowerCase()}`)
                    : sampleRequest.productionSystem || "-"}
                </span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.sex")}</b>
                <span>{sampleRequest.samples?.[0]?.sex || "-"}</span>
              </div>
              <div className={`${styles.data__cell} ${styles["data__cell--half"]}`}>
                <b>{t("sample-request.remarks")}</b>
                <span>{sampleRequest.remarks || "-"}</span>
              </div>
            </div>
            <div className="sub-header">
              <h3>{t("sample-request-read.dietary-info")}</h3>
            </div>
            <div className={styles["data"]}>
              <div className={styles.data__cell}>
                <b>{t("sample-request.d3-level")}</b>
                <span>{sampleRequest.d3Level || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.oh-level")}</b>
                <span>{sampleRequest.samples?.[0]?.dosage || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.ca-level")}</b>
                <span>{sampleRequest.samples?.[0]?.feedComposition?.caLevel || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.p-total")}</b>
                <span>{sampleRequest.samples?.[0]?.feedComposition?.pLevel || "-"}</span>
              </div>
              <div className={styles.data__cell}>
                <b>{t("sample-request.phytase")}</b>
                <span>
                  {sampleRequest.samples?.[0]?.feedComposition?.phytase
                    ? sampleRequest.samples?.[0]?.feedComposition?.phytase.toString() +
                      sampleRequest.samples?.[0]?.feedComposition?.phytaseUnit
                    : "-"}
                </span>
              </div>
              <div className={styles.data__cell}></div>
              <div className={styles.data__cell}></div>
              <div className={styles.data__cell}></div>
            </div>
            <div className="sub-header print-break-before">
              <h3>{t("sample-request-read.results")}</h3>
              <DsmLabel small={true}>{t("sample-request-read.samples", { amount: sampleRequest.samples?.length })}</DsmLabel>
            </div>
            {isResultsPending() ? (
              <DsmAlert closeable={false} header={t("sample-request-read.results-pending-alert.title")}>
                {t("sample-request-read.results-pending-alert.description")}
              </DsmAlert>
            ) : (
              <>
                {breakdownValues[0] + breakdownValues[1] > 0 ? (
                  <div className={styles["health-message"]}>
                    <b>
                      {t("sample-request-read.health-alert.title", {
                        total: toFixedNumber(breakdownValues[0] + breakdownValues[1], 1)
                      })}
                    </b>
                    <div>
                      {" "}
                      {breakdownValues[0] > 0
                        ? t("general.test-reason-result-description.deficient", { percentage: breakdownValues[0] }) +
                          t(`general.test-reason-result-deficient.${problem}`) +
                          " "
                        : null}
                      {breakdownValues[1] > 0
                        ? t("general.test-reason-result-description.insufficient", { percentage: breakdownValues[1] }) +
                          t(`general.test-reason-result-insufficient.${problem}`)
                        : null}
                    </div>
                  </div>
                ) : null}
                <DsmAlert closeable={false} className={styles["hyd-alert"]} variant="blue">
                  <span dangerouslySetInnerHTML={{ __html: t("sample-request-read.hyd-alert.description") }}></span>
                </DsmAlert>
                <div className={`card ${styles["individual-results"]} ${shouldShowChart ? "" : styles["card--sample-table"]}`}>
                  <div className={styles["card-header__container"]}>
                    <h5 className="card__header">{t("sample-request-read.individual-results.title")}</h5>
                    <DsmButtonGroup>
                      <DsmButtonGroupItem onClick={() => setShouldShowChart(true)} active={shouldShowChart}>
                        {t("general.chart")}
                      </DsmButtonGroupItem>
                      <DsmButtonGroupItem onClick={() => setShouldShowChart(false)} active={!shouldShowChart}>
                        {t("general.table")}
                      </DsmButtonGroupItem>
                    </DsmButtonGroup>
                  </div>
                  <Plot
                    className={`scatter ${styles.scatter} ${!shouldShowChart ? styles.hide : ""}`}
                    data={[
                      {
                        y: resultValues,
                        x: sampleRequest?.samples?.map((_, i) => `${i + 1}`) || [],
                        text:
                          sampleRequest?.samples?.map(
                            (_) =>
                              `${_.animalId ? `<br>${t("sample-request.animal")}: <b>${_.animalId}</b>` : ""}
                              ${_.parity ? `<br>${t("sample-request.parity")}: <b>${_.parity}</b>` : ""}
                              ${
                                _.problemAnswer
                                  ? `<br>${t(`general.test-reason-problem.${sampleRequest?.problem?.toLocaleLowerCase()}`)}: <b>${
                                      _.problemAnswer ? t("general.yes-no.yes") : t("general.no")
                                    }</b>`
                                  : ""
                              }`
                          ) || [],
                        mode: "markers",
                        type: "scatter",
                        marker: {
                          size: 12,
                          color: resultValues.map((_) => getMarkerColorForValue(_))
                        },
                        hovertemplate: `${t("sample-request-read.individual-results-chart.xaxis")}: <b>%{y} ${t(
                          "sample-request-read.individual-results-chart.yaxis"
                        )}</b>%{text}<extra></extra>`
                      }
                    ]}
                    layout={{
                      width: isPrinting ? 960 : undefined,
                      paper_bgcolor: "transparent",
                      plot_bgcolor: "transparent",
                      height: 500,
                      hoverlabel: { align: "left", bgcolor: "#fff", bordercolor: "#e0e0e0", font: { color: "#000" } },
                      margin: { l: 48, r: 0, t: 8, b: 8, pad: 8 },
                      font: {
                        family: '"DM Sans", sans-serif'
                      },
                      xaxis: { showgrid: false, showticklabels: false },
                      yaxis: {
                        title: t("sample-request-read.individual-results-chart.yaxis"),
                        range: [0, Math.max(...resultValues.concat([sampleRequest?.phase?.optimum || 0]))],
                        tickvals: [
                          0,
                          sampleRequest.phase?.deficient,
                          sampleRequest.phase?.insufficient,
                          sampleRequest.phase?.adequate,
                          Math.max(...resultValues.concat([sampleRequest?.phase?.optimum || 0]))
                        ],
                        zerolinecolor: "transparent",
                        gridcolor: "transparent"
                      },
                      shapes: [
                        {
                          type: "line",
                          layer: "below",
                          y0: sampleRequest.average || 0,
                          y1: sampleRequest.average || 0,
                          x0: 0,
                          x1: sampleRequest.samples ? sampleRequest.samples.length + 1 : 0,
                          fillcolor: "#808080",
                          opacity: 0.6,
                          line: {
                            color: "#808080",
                            width: 2
                          },
                          label: {
                            font: {
                              size: 14,
                              color: "#808080"
                            },
                            text: ` ${t("sample-request-read.individual-results.average")} (${sampleRequest.average})`,
                            textposition: "start"
                          }
                        }
                      ],
                      dragmode: false
                    }}
                    config={{ staticPlot: false, responsive: true, displayModeBar: false }}
                    useResizeHandler={true}
                    style={{ width: "100%", height: "500px" }}
                  />
                  <DsmTable className={`${shouldShowChart ? styles.hide : ""}`} data={samplesTable}></DsmTable>
                </div>
                <div className={styles.results}>
                  {shouldShowLevels ? (
                    <div className={`card ${styles["results-overview"]}`}>
                      <h5 className="card__header">{t("sample-request-read.result-overview.title")}</h5>
                      <ResultList data={resultList}></ResultList>
                    </div>
                  ) : null}
                  <div className={styles.results__side}>
                    <div className={`card ${styles["results-average"]}`}>
                      <h5 className="card__header">{t("sample-request-read.result-average.title")}</h5>
                      <div className={styles["results-average__average"]}>
                        {t("sample-request-read.result-average.value", {
                          value: sampleRequest.average || 0
                        })}
                      </div>
                    </div>
                    {shouldShowLevels ? (
                      <div className={`card ${styles["results-breakdown"]}`}>
                        <h5 className="card__header">{t("sample-request-read.result-breakdown.title")}</h5>
                        <ResultDonutGraph data={resultDonutGraph}></ResultDonutGraph>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
        <div className={styles["print-footer"]}>
          <span>www.dsm-firmenich.com/anh</span>
          <span>©{new Date().getFullYear()} dsm-firmenich</span>
          <img src={`${DsmCDNUrl}/images/logo.svg`} alt="logo"></img>
        </div>
      </DsmGrid>
      {isLoading ? <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator> : null}
    </>
  );
}

export default SampleRequestRead;
