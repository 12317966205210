import { DsmAlert, DsmFooter, DsmFooterNav, DsmFooterNavigation, DsmIcon } from "@dsm-dcs/design-system-react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import AuthProvider, { AuthContext } from "../contexts/auth.context";
import "./Layout.scss";
import { routes } from "../routes";
import useBreakpoint, { Breakpoints } from "../hooks/breakpoint.hook";
import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import { IBreadcrumb } from "../models/breadcrumb.model";
import { IToast } from "../models/toast.model";
import { IFooterLink } from "../models/footerLink.model";
import { triggerPageAnalytics } from "../helpers/analytics.helper";

function Layout() {
  //Hooks
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const location = useLocation();

  //State
  const [pageTitle, setPageTitle] = useState<string>("");
  const [crumbs, setCrumbs] = useState<IBreadcrumb[]>([]);
  const [toast, setToast] = useState<IToast | null>(null);
  const [timeoutID, setTimeoutID] = useState<number | null>(null);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(null);
      }, 30000);
    }
  }, [toast]);

  useEffect(() => {
    //Skip location change because of code url param change
    if (new URLSearchParams(window.location.search).get("code")) return;

    //Use timeout to prevent multiple calls to analytics because of multiple dependency changes
    if (timeoutID) {
      window.clearTimeout(timeoutID);
    }
    const id = window.setTimeout(() => {
      triggerPageAnalytics(crumbs);
    }, 500);
    setTimeoutID(id);
  }, [location, crumbs]);

  const footerLinks: IFooterLink[] = t("footer.navigation", { returnObjects: true });

  const openCookieSettings = (): void => {
    document.querySelector(".cookiepolicy")?.querySelector("a")?.click();
  };

  return (
    <AuthProvider setToast={setToast}>
      <AuthContext.Consumer>
        {(ctx) => (ctx.isAuthorized ? <Header title={pageTitle} setToast={setToast}></Header> : null)}
      </AuthContext.Consumer>
      <Breadcrumb crumbs={crumbs}></Breadcrumb>
      <main>
        <Outlet context={{ setPageTitle, setCrumbs, setToast }} />
      </main>
      {breakpoint !== Breakpoints.xs ? (
        <DsmFooter variant="minimal">
          <DsmFooterNav slot="banner-navigation">
            {footerLinks.map((link) => (
              <a href={link.url} target="_blank" rel="noreferrer" key={link.label}>
                {link.label}
              </a>
            ))}
            {window.truste ? (
              <a role="button" tabIndex={0} onClick={openCookieSettings}>
                {t("footer.cookie-settings")}
              </a>
            ) : null}
          </DsmFooterNav>
        </DsmFooter>
      ) : (
        <DsmFooterNavigation>
          <NavLink slot="navigation" to={routes.home} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="general/home-line" size="md"></DsmIcon>
            {t("header.navigation.home")}
          </NavLink>
          <NavLink slot="navigation" to={routes.farms} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="maps-travel/map-02" size="md"></DsmIcon>
            {t("header.navigation.farms")}
          </NavLink>
          {/* TODO: Reenable poultry */}
          {/* <NavLink slot="navigation" to={routes.dashboard} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="charts/line-chart-up-04" size="md"></DsmIcon>
            {t("header.navigation.dashboard")}
          </NavLink> */}
          <NavLink slot="navigation" to={routes.support} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="education/book-open-02" size="md"></DsmIcon>
            {t("header.navigation.support")}
          </NavLink>
        </DsmFooterNavigation>
      )}
      {toast ? (
        <DsmAlert className="toast" variant={toast.type || "error"} header="An error occurred">
          {toast.text}
          {toast.error ? <div className="toast__error">{toast.error}</div> : null}
        </DsmAlert>
      ) : null}
    </AuthProvider>
  );
}

export default Layout;
