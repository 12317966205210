import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { API, Auth } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  BaseCustomer,
  GetCustomersForUserQuery,
  GetNotificationsForCustomerQuery,
  GetNotificationsForCustomerQueryVariables,
  Notification,
  UpdateNotificationReadStatusMutation,
  UpdateNotificationReadStatusMutationVariables
} from "../models/API";
import { IUser } from "../models/user.model";
import { Dispatch, SetStateAction } from "react";
import { IToast } from "../models/toast.model";
import { BaseQuery } from "../graphql/interfaces";

export const getCustomer = async (setToast: Dispatch<SetStateAction<IToast | null>>): Promise<BaseCustomer | null> => {
  try {
    const result = await API.graphql<GraphQLQuery<GetCustomersForUserQuery>>({
      query: queries.getCustomersForUser
    });
    return result.data?.getCustomersForUser?.customers?.[0] || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading customer", error: errors && errors.length > 0 ? errors[0].message : "" });
    return null;
  }
};

export const getUser = async (): Promise<IUser> => {
  const userData = await Auth.currentUserInfo();

  const fullName = userData?.attributes
    ? [userData.attributes.given_name, userData.attributes.middle_name, userData.attributes.family_name].filter((_) => !!_).join(" ")
    : "";
  const user: IUser = {
    fullName: fullName,
    email: userData?.attributes?.email || ""
  };
  return user;
};

export const getNotifications = async (customerId: string, setToast: Dispatch<SetStateAction<IToast | null>>): Promise<Notification[]> => {
  try {
    const request: BaseQuery<GetNotificationsForCustomerQueryVariables> = {
      query: queries.getNotificationsForCustomer,
      variables: { customerId }
    };
    const result = await API.graphql<GraphQLQuery<GetNotificationsForCustomerQuery>>(request);
    return result.data?.getNotificationsForCustomer?.notifications || [];
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading notifications", error: errors && errors.length > 0 ? errors[0].message : "" });
    return [];
  }
};

export const updateNotificationReadStatus = async (
  notificationId: string,
  customerId: string,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<void> => {
  try {
    const request: BaseQuery<UpdateNotificationReadStatusMutationVariables> = {
      query: mutations.updateNotificationReadStatus,
      variables: { input: { customerId, id: notificationId } }
    };
    await API.graphql<GraphQLQuery<UpdateNotificationReadStatusMutation>>(request);
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while updating notification read status", error: errors && errors.length > 0 ? errors[0].message : "" });
  }
};
