import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import {
  AddKitRequestMutation,
  AddKitRequestMutationVariables,
  GetKitRequestTableQuery,
  GetKitRequestTableQueryVariables,
  KitRequestInput,
  KitRequestTable,
  KitRequestTableInput
} from "../models/API";
import { API } from "aws-amplify";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { BaseQuery } from "../graphql/interfaces";
import { LocationStepForm } from "../models/forms/location-step-form.model";
import { YesNo } from "../models/enums/yesNo";
import { Dispatch, SetStateAction } from "react";
import { IToast } from "../models/toast.model";

export const getKitRequests = async (
  customerId: string,
  input: KitRequestTableInput,
  setToast: Dispatch<SetStateAction<IToast | null>>
): Promise<KitRequestTable | null> => {
  try {
    const request: BaseQuery<GetKitRequestTableQueryVariables> = {
      query: queries.getKitRequestTable,
      variables: { customerId, input }
    };
    const result = await API.graphql<GraphQLQuery<GetKitRequestTableQuery>>(request);
    return result.data?.getKitRequestTable || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    setToast({ text: "Error while loading kit request", error: errors[0].message });
    return null;
  }
};

export const createKitRequest = async (locationStepForm: LocationStepForm, amountOfKits: number): Promise<string | null> => {
  const kitRequest: KitRequestInput = {
    locationId: locationStepForm.farmId,
    amount: amountOfKits,
    sentToLocation: locationStepForm.useLocationAddress === YesNo.Yes
  };
  if (locationStepForm.useLocationAddress === YesNo.No) {
    kitRequest.contactName = locationStepForm.contactPerson;
    kitRequest.address = {
      street: locationStepForm.street || "",
      number: locationStepForm.number || "",
      postalCode: locationStepForm.postalCode || "",
      city: locationStepForm.city || "",
      state: locationStepForm.state || "",
      countryId: locationStepForm.country || ""
    };
  }

  try {
    const request: BaseQuery<AddKitRequestMutationVariables> = {
      query: mutations.addKitRequest,
      variables: { input: kitRequest }
    };
    const result = await API.graphql<GraphQLQuery<AddKitRequestMutation>>(request);
    return result.data?.addKitRequest?.kitRequest?.id || null;
  } catch (error: unknown) {
    const errors = (error as GraphQLResult).errors || [];
    throw new Error(errors[0].message);
  }
};
