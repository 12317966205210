import { useEffect, useState } from "react";
import { IResultListRow } from "../../models/resultList.model";
import styles from "./ResultList.module.scss";

type Props = {
  data: IResultListRow[];
};

function ResultList({ data }: Props) {
  const [rows, setRows] = useState<IResultListRow[]>([]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <div className={styles.list}>
      {rows.map((row, index) => (
        <div className={styles.row} key={index}>
          <div className={`${styles.value} ${styles[`value--${row.type}`]}`}>{row.value}</div>
          <div className={styles.description}>
            <b>{row.title}</b>
            <span>{row.description}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ResultList;
