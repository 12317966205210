import { DsmButton, DsmModal, DsmIcon } from "@dsm-dcs/design-system-react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import styles from "./orderConfirm.module.scss";

type Props = {
  address: string[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
  disabled: boolean;
};

function OrderConfirm({ address, show, setShow, onSubmit, disabled }: Props) {
  //Hooks
  const { t } = useTranslation();

  //Methods
  const handleCancel = (): void => {
    setShow(false);
  };

  return (
    <DsmModal open={show} header={t("order-new-kit.confirm-modal.header")} icon="general/check-circle" onDsmClosed={handleCancel}>
      {
        <div className={styles.container} slot="content">
          <div>
            <h5>{t("order-new-kit.confirm-modal.address-header")}</h5>
            {address.map((line) => (
              <div key={line}>{line}</div>
            ))}
          </div>
          <div>
            <h5>{t("order-new-kit.confirm-modal.cost-header")}</h5>
            {t("order-new-kit.confirm-modal.cost-text")}
          </div>
          <div>
            <h5> {t("order-new-kit.confirm-modal.terms-header")} </h5>
            <a href={t("order-new-kit.confirm-modal.terms-url")} target="blank">
              {t("order-new-kit.confirm-modal.terms-text")}
            </a>
          </div>
        </div>
      }
      <DsmButton variant="secondary" destructive={true} widthFull={true} slot="actions" onClick={handleCancel}>
        <DsmIcon name="general/x-circle"></DsmIcon>
        {t("general.cancel")}
      </DsmButton>
      <DsmButton disabled={disabled} variant="primary" widthFull={true} slot="actions" onClick={onSubmit}>
        <DsmIcon slot="before" name="finance-ecommerce/shopping-cart-01"></DsmIcon>
        {t("order-new-kit.order")}
      </DsmButton>
    </DsmModal>
  );
}

export default OrderConfirm;
