import * as yup from "yup";
import i18next from "i18next";
import { HousingPoultry, HousingSwine } from "../enums/housing.enum";
import { SpeciesGroup } from "../API";
import { ProductionSystemSwine } from "../enums/productionSystem.enum";

export interface FarmStepForm {
  farmId: string;
  speciesGroup: SpeciesGroup;
  barn?: string | null;
  housingSwine?: HousingSwine;
  housingPoultry?: HousingPoultry;
  productionSwine?: ProductionSystemSwine | null;
  productionSwineOther?: string;
}

export const getFarmStepFormSchema = () => {
  return yup.object({
    farmId: yup.string().required().label(i18next.t("sample-request.farm")),
    speciesGroup: yup.mixed<SpeciesGroup>().oneOf(Object.values(SpeciesGroup)).required(),
    barn: yup.string().notRequired().label(i18next.t("sample-request.barn")),
    housingSwine: yup
      .mixed<HousingSwine>()
      .label(i18next.t("sample-request.housing"))
      .when("speciesGroup", {
        is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Swine,
        then: (schema) => schema.oneOf(Object.values(HousingSwine)).required()
      }),
    //TODO: Reenable poultry
    // housingPoultry: yup
    //   .mixed<HousingPoultry>()
    //   .label(i18next.t("sample-request.housing"))
    //   .when("speciesGroup", {
    //     is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Poultry,
    //     then: (schema) => schema.required().oneOf(Object.values(HousingPoultry))
    //   }),
    productionSwine: yup
      .mixed<ProductionSystemSwine>()
      .label(i18next.t("sample-request.production"))
      .when("speciesGroup", {
        is: (speciesGroup: SpeciesGroup) => speciesGroup === SpeciesGroup.Swine,
        then: (schema) => schema.required().oneOf(Object.values(ProductionSystemSwine))
      }),
    productionSwineOther: yup
      .string()
      .label(i18next.t("sample-request.production-other"))
      .when("productionSwine", {
        is: (productionSystemSwine: ProductionSystemSwine) => productionSystemSwine === ProductionSystemSwine.Other,
        then: (schema) => schema.required()
      })
  });
};
