import { DsmButton, DsmLabel, DsmModal } from "@dsm-dcs/design-system-react";
import QrScanner from "qr-scanner";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import styles from "./QrModal.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  saveFunc: (codes: string[]) => void;
};

export type QrModelRef = {
  open: () => void;
  close: () => void;
};

const QrModal = forwardRef<QrModelRef, Props>(function QrModal({ saveFunc }, ref) {
  const [shouldShowQrScanner, setShouldShowQrScanner] = useState<boolean>(false);
  const [qrScanner, setQrScanner] = useState<QrScanner | null>();
  const [isCameraAllowed, setIsCameraAllowed] = useState<boolean>(false);
  const [scannedCodes, setScannedCodes] = useState<string[]>([]);

  //Hooks
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (): void => {
          initQrScanner();
          setShouldShowQrScanner(true);
        },
        close: (): void => {
          setShouldShowQrScanner(false);
        }
      };
    },
    []
  );

  const initQrScanner = () => {
    if (videoRef.current) {
      const qr = new QrScanner(
        videoRef.current,
        (result) => {
          setScannedCodes((codes) => {
            if (!codes.find((c) => c === result.data)) {
              return [...codes, result.data];
            }
            return codes;
          });
        },
        { highlightCodeOutline: true, highlightScanRegion: true }
      );
      setQrScanner(qr);
    }
  };

  const handleModalChange = (closedState: boolean) => {
    setShouldShowQrScanner(closedState);

    if (closedState) {
      qrScanner?.start().then(
        () => {
          setIsCameraAllowed(false);
        },
        () => {
          setIsCameraAllowed(true);
        }
      );
    } else {
      qrScanner?.stop();
      setScannedCodes([]);
    }
  };

  const handleSave = () => {
    setShouldShowQrScanner(false);
    saveFunc(scannedCodes);
  };

  return (
    <DsmModal open={shouldShowQrScanner} onDsmClosed={(e) => handleModalChange(e.detail)} width="unset" header={t("qr-modal.header")}>
      <div className={styles.modal__content} slot="content">
        <div className={styles.modal__video}>
          <video className={styles.video} ref={videoRef}></video>
          {isCameraAllowed ? <span>{t("qr-modal.camera-error")}</span> : null}
        </div>
        {scannedCodes.length > 0 ? (
          <>
            <h5 className={styles["modal__codes-header"]}>{t("qr-modal.scanned-codes-header")}</h5>
            <div className={styles.modal__codes}>
              {scannedCodes.map((code) => (
                <DsmLabel key={code}>{code}</DsmLabel>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <DsmButton slot="actions" onClick={() => setShouldShowQrScanner(false)} widthFull={true} variant="secondary">
        {t("general.cancel")}
      </DsmButton>
      <DsmButton slot="actions" onClick={handleSave} widthFull={true} disabled={scannedCodes.length === 0}>
        {t("qr-modal.save")}
      </DsmButton>
    </DsmModal>
  );
});

export default QrModal;
