export enum SpecificPhaseLAbel {
  AverageWeight = "AverageWeight"
}

export enum SpecificPhase {
  WeightLessThan10KG = "WeightLessThan10KG",
  Weight10To15KG = "Weight10To15KG",
  Weight15To20KG = "Weight15To20KG",
  WeightMoreThan20KG = "WeightMoreThan20KG",
  Weight30To50KG = "Weight30To50KG",
  Weight50To80KG = "Weight50To80KG",
  WeightMoreThan80KG = "WeightMoreThan80KG"
}

export enum ProblemArea {
  Immunity = "Immunity",
  Bone = "Bone",
  Muscle = "Muscle",
  Reproduction = "Reproduction",
  FarrowingDuration = "FarrowingDuration"
}

export enum SpecificProblemArea {
  Diarrhea = "Diarrhea",
  Mortality = "Mortality",
  ReduceMedication = "ReduceMedication",
  Lameness = "Lameness",
  ImproveFCR = "ImproveFCR",
  Prolificacy = "Prolificacy",
  LitterQuality = "LitterQuality",
  Stillborn = "Stillborn",
  PigletMortality = "PigletMortality",
  PigletDiarrhea = "PigletDiarrhea",
  MilkingEfficiencyWeakPiglets = "MilkingEfficiencyWeakPiglets",
  EarTailNecrosis = "EarTailNecrosis",
  CarcassYield = "CarcassYield",
  Other = "Other"
}

export enum TestReasonProblem {
  Diarrhea = "Diarrhea",
  Sick = "Sick",
  Lameness = "Lameness",
  LamenessGilt = "LamenessGilt",
  Growing = "Growing",
  LitterSize = "LitterSize",
  LitterQuality = "LitterQuality",
  Stillborn = "Stillborn",
  PigletMortality = "PigletMortality",
  PigletDiarrhea = "PigletDiarrhea",
  EarTailNecrosis = "EarTailNecrosis",
  Other = "Other"
}

export interface TestReasonConfig {
  specificPhaseLabel?: string;
  specificPhases: SpecificPhaseOption[];
}

interface SpecificPhaseOption {
  value: string;
  problemAreaOptions: ProblemAreaOption[];
}

interface ProblemAreaOption {
  value: string;
  specificProblemAreaOptions: SpecificProblemAreaOption[];
}

interface SpecificProblemAreaOption {
  value: string;
  problem: string;
}
