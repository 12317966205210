/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "./Create.module.scss";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../components/pageHeader/PageHeader";
import useBreakpoint, { Breakpoints } from "../../../hooks/breakpoint.hook";
import { useContext, useEffect, useRef, useState } from "react";
import { routeTypes } from "../../../routes";
import { useLayout } from "../../../contexts/layout.context";
import { DsmSelectOption, ProgressStep } from "@dsm-dcs/design-system";
import { DsmButton, DsmGrid, DsmIcon, DsmLoadingIndicator, DsmProgressSteps } from "@dsm-dcs/design-system-react";
import { getFarmStepFormSchema } from "../../../models/forms/farm-step-form.model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { AuthContext } from "../../../contexts/auth.context";
import { BaseLocation, Genetic, Phase, SpeciesGroup } from "../../../models/API";
import Select from "../../../components/form/select/Select";
import { getLocationsForCustomer } from "../../../services/location.service";
import Input from "../../../components/form/input/Input";
import RadioButtonGroup from "../../../components/form/radioButtonGroup/RadioButtonGroup";
import { HousingSwine } from "../../../models/enums/housing.enum";
import { ProductionSystemSwine } from "../../../models/enums/productionSystem.enum";
import { convertPhasesForSelect, getPhase, getSpeciesForSelect } from "../../../services/metaData.service";
import { getAnimalStepFormSchema } from "../../../models/forms/animal-step-form.model";
import { Sex } from "../../../models/enums/sex.enum";
import { getDietStepFormSchema } from "../../../models/forms/diet-step-form.model";
import NumberInput from "../../../components/form/numberInput/NumberInput";
import DateInput from "../../../components/form/dateInput/DateInput";
import TextArea from "../../../components/form/textArea/TextArea";
import { PhytaseUnit } from "../../../models/enums/phytaseUnit.enum";
import { getSamplesStepFormSchema } from "../../../models/forms/samples-step-form.mode";
import QrModal, { QrModelRef } from "../../../components/qrModal/QrModal";
import { createSampleRequest } from "../../../services/sample.service";
import { YesNo } from "../../../models/enums/yesNo";
import { TestReasonConfig } from "../../../models/testReason.model";
import ConfirmModal from "./confirmModal/confirmModal";

function SampleRequestCreate() {
  //Hooks
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs } = useLayout();
  const breakpoint = useBreakpoint();
  const { customer } = useContext(AuthContext);
  const { setToast } = useLayout();

  //State
  const [steps, setSteps] = useState<ProgressStep[]>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  //Farm step
  const farmStepSchema = getFarmStepFormSchema();
  const {
    watch: farmStepWatch,
    setValue: farmStepSetValue,
    resetField: farmStepResetField,
    control: farmStepControl,
    trigger: farmStepTrigger,
    getValues: farmStepGetValues,
    formState: { errors: farmStepErrors, isValid: isFarmStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(farmStepSchema)
  });
  const farmId = farmStepWatch("farmId");
  const productionSwine = farmStepWatch("productionSwine");
  const [farms, setFarms] = useState<BaseLocation[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<BaseLocation | null>(null);
  const [farmOptions, setFarmOptions] = useState<DsmSelectOption[]>([]);
  const [housingSwineOptions, setHousingSwineOptions] = useState<DsmSelectOption[]>([]);
  //TODO: Reenable poultry
  // const [housingPoultryOptions, setHousingPoultryOptions] = useState<DsmSelectOption[]>([]);
  const [productionSystemSwineOptions, setProductionSystemSwineOptions] = useState<DsmSelectOption[]>([]);

  //Animal step
  const [speciesOptions, setSpeciesOptions] = useState<DsmSelectOption[]>([]);
  const [isLoadingSpecies, setIsLoadingSpecies] = useState<boolean>(false);
  const [isReproduction, setIsReproduction] = useState<boolean>(false);
  const [phases, setPhases] = useState<Phase[]>([]);
  const [phaseOptions, setPhaseOptions] = useState<DsmSelectOption[]>([]);
  const [isLoadingPhase, setIsLoadingPhase] = useState<boolean>(false);
  const [specificPhaseOptions, setSpecificPhaseOptions] = useState<DsmSelectOption[]>([]);
  const [problemAreaOptions, setProblemAreaOptions] = useState<DsmSelectOption[]>([]);
  const [specificProblemAreaOptions, setSpecificProblemAreaOptions] = useState<DsmSelectOption[]>([]);
  const [geneticSupplierSwineOptions, setGeneticSupplierSwineOptions] = useState<DsmSelectOption[]>([]);
  const [geneticLineageSwineOptions, setGeneticLineageSwineOptions] = useState<DsmSelectOption[]>([]);
  const [sexOptions, setSexOptions] = useState<DsmSelectOption[]>([]);
  const animalStepSchema = getAnimalStepFormSchema();
  const {
    watch: animalStepWatch,
    setValue: animalStepSetValue,
    resetField: animalStepResetField,
    control: animalStepControl,
    trigger: animalStepTrigger,
    getValues: animalStepGetValues,
    formState: { errors: animalStepErrors, isValid: isAnimalStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(animalStepSchema),
    defaultValues: {
      sex: Sex.Mixed,
      hasSpecificPhase: false
    }
  });
  const speciesId = animalStepWatch("speciesId");
  const phaseId = animalStepWatch("phaseId");
  const specificPhaseValue = animalStepWatch("specificPhase");
  const problemAreaValue = animalStepWatch("problemArea");
  const specificProblemAreaValue = animalStepWatch("specificProblemArea");
  const geneticSupplier = animalStepWatch("geneticSupplier");
  const geneticLineage = animalStepWatch("geneticLineage");
  const [testReasonConfig, setTestReasonConfig] = useState<TestReasonConfig | null>(null);
  const [testReasonProblem, setTestReasonProblem] = useState<string | null>(null);

  //Diet step
  const [phytaseUnitOptions, setPhytaseUnitOptions] = useState<DsmSelectOption[]>([]);
  const dietStepFormSchema = getDietStepFormSchema();
  const {
    control: dietStepControl,
    trigger: dietStepTrigger,
    getValues: dietStepGetValues,
    formState: { errors: dietStepErrors, isValid: isDietStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(dietStepFormSchema),
    defaultValues: {
      phytaseUnit: PhytaseUnit.FTU
    }
  });

  //Samples step
  const qrScannerRef = useRef<QrModelRef | null>(null);
  const samplesStepFormSchema = getSamplesStepFormSchema();
  const {
    control: samplesStepControl,
    trigger: samplesStepTrigger,
    getValues: samplesStepGetValues,
    formState: { errors: samplesStepErrors, isValid: isSamplesStepValid }
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(samplesStepFormSchema),
    defaultValues: {
      cards: [{}]
    }
  });
  const {
    fields: samplesFields,
    remove: removeSample,
    append: appendSample
  } = useFieldArray({ name: "cards", control: samplesStepControl });
  const [yesNoOptions, setYesNoOptions] = useState<DsmSelectOption[]>([]);

  //Effects
  useEffect(() => {
    setPageTitle(t("sample-request-create.page.title"));
    setCrumbs([{ title: t("sample-request-create.page.title"), type: routeTypes.sampleRequestCreate }]);
    initData();
  }, []);

  useEffect(() => {
    setSteps((steps) => {
      steps[0].completed = isFarmStepValid;
      steps[1].completed = isFarmStepValid && isAnimalStepValid;
      steps[1].disabled = !isFarmStepValid;
      steps[2].completed = isFarmStepValid && isAnimalStepValid && isDietStepValid;
      steps[2].disabled = !isFarmStepValid || !isAnimalStepValid || !isDietStepValid;
      steps[3].completed = isFarmStepValid && isAnimalStepValid && isDietStepValid && isSamplesStepValid;
      steps[3].disabled = !isFarmStepValid || !isAnimalStepValid || !isDietStepValid || !isSamplesStepValid;
      return steps;
    });
  }, [isFarmStepValid, isAnimalStepValid, isDietStepValid, isSamplesStepValid]);

  useEffect(() => {
    if (farmId) {
      handleLocationChange();
    }
  }, [farmId]);

  useEffect(() => {
    handleSpeciesChange();
  }, [speciesId]);

  useEffect(() => {
    handlePhaseChange();
  }, [phaseId]);

  useEffect(() => {
    handleSpecificPhaseChange();
  }, [specificPhaseValue]);

  useEffect(() => {
    handleProblemAreaChange();
  }, [problemAreaValue]);

  useEffect(() => {
    handleSpecificProblemAreaChange();
  }, [specificProblemAreaValue]);

  useEffect(() => {
    if (speciesOptions && speciesOptions.length === 1) {
      animalStepSetValue("speciesId", speciesOptions[0].value);
    } else {
      animalStepResetField("speciesId");
    }
  }, [speciesOptions]);

  //Method
  const initData = async () => {
    setSteps([
      {
        id: "1",
        completed: false,
        title: t("sample-request-create.farm-step.step.header"),
        description: t("sample-request-create.farm-step.step.description")
      },
      {
        id: "2",
        completed: false,
        title: t("sample-request-create.animal-step.step.header"),
        description: t("sample-request-create.animal-step.step.description")
      },
      {
        id: "3",
        completed: false,
        title: t("sample-request-create.diet-step.step.header"),
        description: t("sample-request-create.diet-step.step.description")
      },
      {
        id: "4",
        completed: false,
        title: t("sample-request-create.samples-step.step.header"),
        description: t("sample-request-create.samples-step.step.description")
      }
    ]);
    setHousingSwineOptions(
      Object.keys(HousingSwine).map((housing) => {
        return { value: housing, text: t(`general.housing-swine.${housing.toLocaleLowerCase()}`) };
      })
    );
    //TODO: Reenable poultry
    // setHousingPoultryOptions(
    //   Object.keys(HousingPoultry).map((housing) => {
    //     return { value: housing, text: t(`general.housing-poultry.${housing.toLocaleLowerCase()}`) };
    //   })
    // );
    setProductionSystemSwineOptions(
      Object.keys(ProductionSystemSwine).map((productionSystem) => {
        return { value: productionSystem, text: t(`general.production-system-swine.${productionSystem.toLocaleLowerCase()}`) };
      })
    );
    setSexOptions(
      Object.keys(Sex).map((sex) => {
        return { value: sex, text: t(`general.sex.${sex.toLocaleLowerCase()}`) };
      })
    );
    setPhytaseUnitOptions(
      Object.keys(PhytaseUnit).map((phytaseUnit) => {
        return { value: phytaseUnit, text: t(`general.phytase-unit.${phytaseUnit.toLocaleLowerCase()}`) };
      })
    );
    setYesNoOptions(
      Object.keys(YesNo).map((option) => {
        return { value: option, text: t(`general.yes-no.${option.toLocaleLowerCase()}`) };
      })
    );

    await initFarmStep();
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = (): void => {
    if (activeStep === 1 && !isFarmStepValid) {
      farmStepTrigger();
      return;
    }
    if (activeStep === 2 && (!isFarmStepValid || !isAnimalStepValid)) {
      animalStepTrigger();
      return;
    }
    if (activeStep === 3 && (!isFarmStepValid || !isAnimalStepValid || !isDietStepValid)) {
      dietStepTrigger();
      return;
    }
    if (activeStep === 4 && (!isFarmStepValid || !isAnimalStepValid || !isDietStepValid || !isSamplesStepValid)) {
      samplesStepTrigger();
      return;
    }
    if (activeStep === 4 && (isFarmStepValid || isAnimalStepValid || isDietStepValid || isSamplesStepValid)) {
      handleSubmit();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      await createSampleRequest(
        farmStepGetValues(),
        animalStepGetValues(),
        dietStepGetValues(),
        samplesStepGetValues(),
        testReasonProblem,
        isReproduction
      );
      setIsSubmitted(true);
    } catch (e: unknown) {
      const error = e as Error;
      setIsSubmitting(false);
      setToast({ text: t("general.error", { action: t("sample-request-create.error-action") }), error: error.message });
    }
  };

  const initFarmStep = async () => {
    const farms = await getLocationsForCustomer(customer?.id || "", setToast);
    setFarms(farms);
    const options = farms.map((farm) => {
      return { value: farm.id || "", text: farm.name || "" };
    });
    options.sort((a, b) => a.text.localeCompare(b.text));
    setFarmOptions(options);
    setIsLoading(false);
  };

  const handleLocationChange = async () => {
    farmStepResetField("speciesGroup");
    animalStepResetField("speciesGroup");
    animalStepResetField("speciesId");
    const farm = farms.find((_) => _.id === farmId);
    setSelectedFarm(farm || null);
    const newSpeciesGroup = farm?.groups?.[0];
    if (newSpeciesGroup && selectedFarm?.groups?.[0] !== newSpeciesGroup) {
      farmStepResetField("housingSwine");
      //TODO: Reenable poultry
      // farmStepResetField("housingPoultry");
      farmStepResetField("productionSwine");
      farmStepSetValue("speciesGroup", newSpeciesGroup);
      animalStepSetValue("speciesGroup", newSpeciesGroup);
      setIsLoadingSpecies(true);
      setSpeciesOptions([]);
      const species = await getSpeciesForSelect(newSpeciesGroup, setToast);
      setSpeciesOptions(species);
      setIsLoadingSpecies(false);
    }
  };

  const handleSpeciesChange = async () => {
    animalStepResetField("phaseId");

    if (speciesId) {
      setIsLoadingPhase(true);
      setPhases([]);
      setPhaseOptions([]);
      const phases = await getPhase(speciesId, setToast);
      setPhases(phases);
      setPhaseOptions(convertPhasesForSelect(phases));
      setIsLoadingPhase(false);
    }
  };

  const handlePhaseChange = (): void => {
    animalStepResetField("specificPhase");
    animalStepResetField("problemArea");
    animalStepResetField("geneticSupplier");
    animalStepResetField("geneticSupplierOther");
    animalStepResetField("geneticLineage");
    animalStepResetField("geneticLineageOther");
    animalStepSetValue("hasSpecificPhase", false);
    setSpecificPhaseOptions([]);
    setGeneticSupplierSwineOptions([]);
    setGeneticLineageSwineOptions([]);
    const phase = phases.find((_) => _.id === phaseId);

    if (phase && phase.name) {
      const isReproduction = phase.name.toLocaleLowerCase().includes("reproduction");
      setIsReproduction(isReproduction); //TODO: Use setting from backend
    } else {
      setIsReproduction(false);
    }

    if (phase?.geneticSupplier && phase.geneticSupplier.length > 0) {
      const supplierOptions = phase.geneticSupplier
        .filter((_): _ is Genetic => _ !== null)
        .map((supplier: Genetic) => ({ value: supplier.name ?? "", text: supplier.name ?? "" }));
      setGeneticSupplierSwineOptions(supplierOptions);
      if (supplierOptions.length === 1) {
        animalStepSetValue("geneticSupplier", supplierOptions[0].value);
        animalStepSetValue("geneticSupplierOther", supplierOptions[0].value);
      }
    }

    if (phase?.geneticLineage && phase.geneticLineage.length > 0) {
      const lineageOptions = phase.geneticLineage
        .filter((_): _ is Genetic => _ !== null)
        .map((lineage: Genetic) => ({ value: lineage.name ?? "", text: lineage.name ?? "" }));
      setGeneticLineageSwineOptions(lineageOptions);
      if (lineageOptions.length === 1) {
        animalStepSetValue("geneticLineage", lineageOptions[0].value);
        animalStepSetValue("geneticLineageOther", lineageOptions[0].value);
      }
    }

    if (phase && phase.testReasonConfig) {
      const testReasonConfig = JSON.parse(JSON.parse(phase.testReasonConfig));
      setTestReasonConfig(testReasonConfig);
      const specificPhases = testReasonConfig?.specificPhases?.map((specificPhase: any) => specificPhase.value) || [];
      if (specificPhases.length === 1 && specificPhases[0] === "") {
        animalStepSetValue("specificPhase", "");
      }
      setSpecificPhaseOptions(
        specificPhases.map((specificPhase: any) => {
          return { value: specificPhase, text: t(`general.specific-phase.${specificPhase.toLocaleLowerCase()}`) };
        })
      );
      animalStepSetValue("hasSpecificPhase", specificPhases.length > 1);
    } else {
      setTestReasonConfig(null);
    }
  };

  const handleSpecificPhaseChange = (): void => {
    animalStepResetField("problemArea");
    const specificPhase = testReasonConfig?.specificPhases.find((specificPhase: any) => specificPhase.value === specificPhaseValue);
    const problemAreas = specificPhase?.problemAreaOptions.map((problemArea: any) => problemArea.value) || [];
    setProblemAreaOptions(
      problemAreas.map((problemArea: any) => {
        return { value: problemArea, text: t(`general.problem-area.${problemArea.toLocaleLowerCase()}`) };
      })
    );
  };

  const handleProblemAreaChange = (): void => {
    animalStepResetField("specificProblemArea");
    const specificPhase = testReasonConfig?.specificPhases.find((specificPhase: any) => specificPhase.value === specificPhaseValue);
    const problemArea = specificPhase?.problemAreaOptions.find((problemArea: any) => problemArea.value === problemAreaValue);
    const specificProblemAreas = problemArea?.specificProblemAreaOptions.map((specificProblemArea: any) => specificProblemArea.value) || [];
    setSpecificProblemAreaOptions(
      specificProblemAreas.map((specificProblemArea: any) => {
        return { value: specificProblemArea, text: t(`general.specific-problem-area.${specificProblemArea.toLocaleLowerCase()}`) };
      })
    );
  };

  const handleSpecificProblemAreaChange = (): void => {
    const specificPhase = testReasonConfig?.specificPhases.find((specificPhase: any) => specificPhase.value === specificPhaseValue);
    const problemArea = specificPhase?.problemAreaOptions.find((problemArea: any) => problemArea.value === problemAreaValue);
    const specificProblemArea = problemArea?.specificProblemAreaOptions.find(
      (specificProblemArea: any) => specificProblemArea.value === specificProblemAreaValue
    );
    setTestReasonProblem(specificProblemArea?.problem || null);
  };

  const handleAddCard = (code: string) => {
    appendSample({ card: code });
  };

  const handleRemoveCard = (index: number) => {
    removeSample(index);
  };

  const openQrScanner = () => {
    qrScannerRef?.current?.open();
  };

  const handleScannedCodes = (codes: string[]) => {
    if (codes && codes.length > 0) {
      samplesFields.forEach((field, index) => {
        if (!field.card) {
          handleRemoveCard(index);
        }
      });
      codes.forEach((code) => {
        if (!samplesFields.find((_) => _.card === code)) {
          handleAddCard(code);
        }
      });
    }
  };

  const cardRowHasError = (index: number): boolean => {
    return !!(
      samplesStepErrors.cards?.[index]?.card ||
      samplesStepErrors.cards?.[index]?.animal ||
      samplesStepErrors.cards?.[index]?.parity ||
      samplesStepErrors.cards?.[index]?.problemAnswer
    );
  };

  return (
    <>
      <DsmGrid className={`main-container main-container--with-breadcrumb ${styles["grid"]}`} fixed={true} container-only={true}>
        <PageHeader header={t("sample-request-create.page.title")} description={t("sample-request-create.page.description")}></PageHeader>
        <DsmProgressSteps
          className={styles["progress"]}
          stacked={breakpoint === Breakpoints.xs}
          steps={steps}
          active-step={activeStep}
          onDsmStepChange={(e) => setActiveStep(Number.parseInt(e.detail))}
        ></DsmProgressSteps>
        <div className={`${styles.step} ${activeStep === 1 ? styles["step--active"] : ""}`}>
          {!isLoading ? (
            <div className={`${styles.form}`}>
              <div className={styles.form__part}>
                <Select
                  fieldName="farmId"
                  control={farmStepControl}
                  options={farmOptions}
                  label={t("sample-request.farm")}
                  fieldError={farmStepErrors.farmId}
                  schema={farmStepSchema}
                ></Select>
                <Input
                  control={farmStepControl}
                  fieldName="barn"
                  label={t("sample-request.barn")}
                  tooltip={t("sample-request-create.tooltip-barn")}
                  fieldError={farmStepErrors.barn}
                  schema={farmStepSchema}
                ></Input>
              </div>
              <div className={styles.form__part}>
                {!selectedFarm?.groups?.[0] || selectedFarm?.groups?.[0] === SpeciesGroup.Swine ? (
                  <>
                    <RadioButtonGroup
                      control={farmStepControl}
                      fieldName="housingSwine"
                      label={t("sample-request.housing")}
                      tooltip={t("sample-request-create.tooltip-housing")}
                      options={!selectedFarm?.groups?.[0] ? [] : housingSwineOptions}
                      noOptionsText={t("general.select-farm")}
                      fieldError={farmStepErrors.housingSwine}
                      disabled={!selectedFarm?.groups?.[0]}
                      forceRequired={true} //Cannot use isRequired because it is conditional
                    ></RadioButtonGroup>
                  </>
                ) : null}
                {/* TODO: Reenable poultry */}
                {/* {selectedFarm?.groups?.[0] === SpeciesGroup.Poultry ? (
                  <RadioButtonGroup
                    control={farmStepControl}
                    fieldName="housingPoultry"
                    label={t("sample-request.housing")}
                    tooltip={t("sample-request-create.tooltip-housing")}
                    options={housingPoultryOptions}
                    fieldError={farmStepErrors.housingPoultry}
                    forceRequired={true} //Cannot use isRequired because it is conditional
                  ></RadioButtonGroup>
                ) : null} */}
                {!selectedFarm?.groups?.[0] || selectedFarm?.groups?.[0] === SpeciesGroup.Swine ? (
                  <>
                    <RadioButtonGroup
                      control={farmStepControl}
                      fieldName="productionSwine"
                      label={t("sample-request.production")}
                      options={!selectedFarm?.groups?.[0] ? [] : productionSystemSwineOptions}
                      noOptionsText={t("general.select-farm")}
                      fieldError={farmStepErrors.productionSwine}
                      disabled={!selectedFarm?.groups?.[0]}
                      forceRequired={true} //Cannot use isRequired because it is conditional
                    ></RadioButtonGroup>
                    {productionSwine === ProductionSystemSwine.Other ? (
                      <Input
                        control={farmStepControl}
                        fieldName="productionSwineOther"
                        label={t("sample-request.production-other")}
                        fieldError={farmStepErrors.productionSwineOther}
                        forceRequired={true} //Cannot use isRequired because it is conditional
                      ></Input>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          ) : (
            <DsmLoadingIndicator className={styles["loader"]} size="md"></DsmLoadingIndicator>
          )}
        </div>
        <div className={`${styles.step} ${activeStep === 2 ? styles["step--active"] : ""}`}>
          <div className={`${styles.form}`}>
            <div className={styles.form__part}>
              <Select
                fieldName="speciesId"
                control={animalStepControl}
                options={speciesOptions}
                label={t("sample-request.species")}
                fieldError={animalStepErrors.speciesId}
                schema={animalStepSchema}
                disabled={speciesOptions.length === 0}
                loading={isLoadingSpecies}
              ></Select>
              <Select
                fieldName="phaseId"
                control={animalStepControl}
                options={phaseOptions}
                label={t("sample-request.phase")}
                tooltip={t("sample-request-create.tooltip-phase")}
                fieldError={animalStepErrors.phaseId}
                schema={animalStepSchema}
                disabled={phaseOptions.length === 0}
                loading={isLoadingPhase}
              ></Select>
              {specificPhaseOptions.length > 1 ? (
                <Select
                  fieldName="specificPhase"
                  control={animalStepControl}
                  options={specificPhaseOptions}
                  label={
                    testReasonConfig?.specificPhaseLabel
                      ? t(`general.specific-phase-label.${testReasonConfig.specificPhaseLabel.toLocaleLowerCase()}`)
                      : t("sample-request.specific-phase")
                  }
                  fieldError={animalStepErrors.specificPhase}
                  forceRequired={true} //Cannot use isRequired because it is conditional
                ></Select>
              ) : null}
              <Select
                fieldName="problemArea"
                control={animalStepControl}
                options={problemAreaOptions}
                label={t("sample-request.problem-area")}
                fieldError={animalStepErrors.problemArea}
                schema={animalStepSchema}
                disabled={problemAreaOptions.length === 0}
              ></Select>
              <Select
                fieldName="specificProblemArea"
                control={animalStepControl}
                options={specificProblemAreaOptions}
                label={t("sample-request.specific-problem-area")}
                fieldError={animalStepErrors.specificProblemArea}
                schema={animalStepSchema}
                disabled={specificProblemAreaOptions.length === 0}
              ></Select>
            </div>
            <div className={styles.form__part}>
              <RadioButtonGroup
                control={animalStepControl}
                fieldName="sex"
                label={t("sample-request.sex")}
                options={sexOptions}
                fieldError={animalStepErrors.sex}
                schema={animalStepSchema}
              ></RadioButtonGroup>
              {selectedFarm?.groups?.[0] === SpeciesGroup.Swine && geneticSupplierSwineOptions.length > 1 ? (
                <>
                  <Select
                    fieldName="geneticSupplier"
                    control={animalStepControl}
                    options={geneticSupplierSwineOptions}
                    label={t("sample-request.genetic-supplier")}
                    fieldError={animalStepErrors.geneticSupplier}
                    forceRequired={true} //Cannot use isRequired because it is conditional
                  ></Select>
                  {geneticSupplier === "Other" ? (
                    <Input
                      control={animalStepControl}
                      fieldName="geneticSupplierOther"
                      label={t("sample-request.genetic-supplier-other")}
                      fieldError={animalStepErrors.geneticSupplierOther}
                      forceRequired={true} //Cannot use isRequired because it is conditional
                    ></Input>
                  ) : null}
                </>
              ) : null}
              {selectedFarm?.groups?.[0] === SpeciesGroup.Swine && geneticLineageSwineOptions.length > 1 ? (
                <>
                  <Select
                    fieldName="geneticLineage"
                    control={animalStepControl}
                    options={geneticLineageSwineOptions}
                    label={t("sample-request.genetic-lineage")}
                    fieldError={animalStepErrors.geneticLineage}
                    forceRequired={true} //Cannot use isRequired because it is conditional
                  ></Select>
                  {geneticLineage === "Other" ? (
                    <Input
                      control={animalStepControl}
                      fieldName="geneticLineageOther"
                      label={t("sample-request.genetic-lineage-other")}
                      fieldError={animalStepErrors.geneticLineageOther}
                      forceRequired={true} //Cannot use isRequired because it is conditional
                    ></Input>
                  ) : null}
                </>
              ) : null}
              {/* TODO: Reenable poultry */}
              {/* <Input
                  control={animalStepControl}
                  fieldName="genetics"
                  label={t("sample-request.genetics")}
                  fieldError={animalStepErrors.genetics}
                  forceRequired={true} //Cannot use isRequired because it is conditional
                ></Input> */}
            </div>
          </div>
        </div>
        <div className={`${styles.step} ${activeStep === 3 ? styles["step--active"] : ""}`}>
          <div className={`${styles.form}`}>
            <div className={styles.form__part}>
              <NumberInput
                control={dietStepControl}
                fieldName="d3Level"
                label={t("sample-request.d3-level")}
                tooltip={t("sample-request-create.tooltip-d3-level")}
                fieldError={dietStepErrors.d3Level}
                schema={dietStepFormSchema}
                unit={t("sample-request.d3-level-unit")}
              ></NumberInput>
              <NumberInput
                control={dietStepControl}
                fieldName="ohLevel"
                label={t("sample-request.oh-level")}
                tooltip={t("sample-request-create.tooltip-oh-level")}
                fieldError={dietStepErrors.ohLevel}
                schema={dietStepFormSchema}
                unit={t("sample-request.oh-level-unit")}
              ></NumberInput>
            </div>
            <div className={styles.form__part}>
              <NumberInput
                control={dietStepControl}
                fieldName="caLevel"
                label={t("sample-request.ca-level")}
                tooltip={t("sample-request-create.tooltip-ca-level")}
                fieldError={dietStepErrors.caLevel}
                schema={dietStepFormSchema}
                unit={t("sample-request.ca-level-unit")}
              ></NumberInput>
              <NumberInput
                control={dietStepControl}
                fieldName="pTotal"
                label={t("sample-request.p-total")}
                tooltip={t("sample-request-create.tooltip-p-total")}
                fieldError={dietStepErrors.pTotal}
                schema={dietStepFormSchema}
                unit={t("sample-request.p-total-unit")}
              ></NumberInput>
              <div>
                <div className={styles["form__unit-row"]}>
                  <NumberInput
                    control={dietStepControl}
                    fieldName="phytase"
                    label={t("sample-request.phytase")}
                    tooltip={t("sample-request-create.tooltip-phytase")}
                    fieldError={dietStepErrors.phytase}
                    schema={dietStepFormSchema}
                  ></NumberInput>
                  <Select
                    fieldName="phytaseUnit"
                    control={dietStepControl}
                    options={phytaseUnitOptions}
                    fieldError={dietStepErrors.phytaseUnit}
                    schema={dietStepFormSchema}
                  ></Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.step} ${activeStep === 4 ? styles["step--active"] : ""}`}>
          <div className={`${styles.form}`}>
            <div className={styles.form__part}>
              <DateInput
                control={samplesStepControl}
                fieldName="collectionDate"
                label={t("sample-request.collection-date")}
                fieldError={samplesStepErrors.collectionDate}
                schema={samplesStepFormSchema}
              ></DateInput>
            </div>
            <div className={styles.form__part}>
              <TextArea
                control={samplesStepControl}
                fieldName="remarks"
                label={t("sample-request.remarks")}
                rows={4}
                fieldError={samplesStepErrors.remarks}
                schema={samplesStepFormSchema}
              ></TextArea>
            </div>
          </div>
          <div className={styles["cards"]}>
            {samplesStepErrors.cards?.message ? <div className={styles["form-error"]}>{samplesStepErrors.cards?.message}</div> : null}
            {samplesFields.map((field, index) => {
              return (
                <div key={field.id} className={styles["cards__row"]}>
                  <div className={`${styles["cards__fields"]} ${cardRowHasError(index) ? styles["cards__fields--error"] : ""}`}>
                    <Input
                      control={samplesStepControl}
                      fieldName={`cards.${index}.card`}
                      fieldError={samplesStepErrors.cards?.[index]?.card}
                      label={t("sample-request.card")}
                      forceRequired={true}
                    ></Input>
                    <Input
                      control={samplesStepControl}
                      fieldName={`cards.${index}.animal`}
                      fieldError={samplesStepErrors.cards?.[index]?.animal}
                      label={t("sample-request.animal")}
                      tooltip={t("sample-request-create.tooltip-animal")}
                    ></Input>
                    {isReproduction ? (
                      <Input
                        control={samplesStepControl}
                        fieldName={`cards.${index}.parity`}
                        fieldError={samplesStepErrors.cards?.[index]?.parity}
                        label={t("sample-request.parity")}
                      ></Input>
                    ) : null}
                    {testReasonProblem ? (
                      <RadioButtonGroup
                        control={samplesStepControl}
                        fieldName={`cards.${index}.problemAnswer`}
                        options={yesNoOptions}
                        fieldError={samplesStepErrors.cards?.[index]?.problemAnswer}
                        label={t(`general.test-reason-problem.${testReasonProblem.toLocaleLowerCase()}`)}
                      ></RadioButtonGroup>
                    ) : null}
                  </div>
                  {samplesFields.length > 1 ? (
                    <DsmIcon
                      className={styles["cards__delete-icon"]}
                      name="general/trash-01"
                      size="md"
                      onClick={() => handleRemoveCard(index)}
                    ></DsmIcon>
                  ) : null}
                </div>
              );
            })}
            <div className={styles.cards__actions}>
              <DsmButton onClick={() => handleAddCard("")}>
                {t("sample-request-create.add-card")}
                <DsmIcon slot="before" name="general/plus"></DsmIcon>
              </DsmButton>
              <DsmButton onClick={openQrScanner}>
                {t("sample-request-create.scan-cards")}
                <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
              </DsmButton>
            </div>
          </div>
        </div>
        <div className={styles["progress-controls"]}>
          <div>
            {activeStep > 1 ? (
              <DsmButton variant="primary" disabled={activeStep === 1} onClick={handleBack}>
                <DsmIcon name="arrows/arrow-left"></DsmIcon>
                {t("general.back")}
              </DsmButton>
            ) : null}
          </div>
          <DsmButton variant="primary" disabled={isSubmitting} onClick={handleNext}>
            <DsmIcon name={activeStep === 4 ? "general/save-01" : "arrows/arrow-right"}></DsmIcon>
            {activeStep === 4 ? t("sample-request-create.submit") : t("general.next")}
          </DsmButton>
        </div>
      </DsmGrid>
      <QrModal saveFunc={handleScannedCodes} ref={qrScannerRef}></QrModal>
      <ConfirmModal show={isSubmitted}></ConfirmModal>
    </>
  );
}

export default SampleRequestCreate;
