import { DsmBreadcrumb, DsmGrid } from "@dsm-dcs/design-system-react";
import styles from "./Breadcrumb.module.scss";
import { IBreadcrumb } from "../../models/breadcrumb.model";
import { useNavigate } from "react-router-dom";

type Props = {
  crumbs: IBreadcrumb[];
};

function Breadcrumb({ crumbs }: Props) {
  //Hooks
  const navigate = useNavigate();

  //Methods
  const handleClick = (url?: string) => {
    if (url) {
      navigate(url);
    }
  };

  const getCrumbs = (): IBreadcrumb[] => {
    return crumbs.filter((crumb) => crumb.title !== "");
  };

  return (
    <>
      {getCrumbs().length > 0 ? (
        <DsmGrid className={styles.breadcrumb} fixed={true} container-only={true}>
          <DsmBreadcrumb>
            {crumbs.map((crumb, index) => (
              <a onClick={() => handleClick(crumb.url)} key={`${index}-${crumb.title}`}>
                {crumb.title}
              </a>
            ))}
          </DsmBreadcrumb>
        </DsmGrid>
      ) : null}
    </>
  );
}

export default Breadcrumb;
