import { useEffect } from "react";

function SSOLogin() {
  useEffect(() => {
    if (window.gigya) return;

    const script = document.createElement("script");
    script.src = "https://cdns.gigya.com/js/gigya.js?apiKey=" + process.env.REACT_APP_ADOBE_GIGYA_KEY;
    script.type = "text/javascript";
    script.onload = onLoad;
    document.body.appendChild(script);
  });

  const onLoad = () => {

    const redirectToProxy = () => {
      const url = window.gigya.utils.URL.addParamsToURL("<Your-Defined-Proxy-Page-URI>",{
          mode: 'afterLogin'
      });
      window.location.href = url;
    }

    window.gigya?.accounts.addEventHandlers({
      onLogin: function () {
        redirectToProxy();
        //window.gigya.fidm.saml.continueSSO();
      }
    });

    window.gigya?.accounts.showScreenSet({
      screenSet: "VeraxAnalytics-Login",
      containerID: "container",
      sessionExpiration: "20000"
    });
  };

  return <div id="container"></div>;
}

export default SSOLogin;