import { ReactNode, useContext } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { DsmLoadingIndicator } from "@dsm-dcs/design-system-react";

type Props = {
  children?: ReactNode;
};

function RequireAuth({ children }: Props) {
  //Hooks
  const { isLoading, isAuthorized } = useContext(AuthContext);

  return <>{isAuthorized && !isLoading ? children : <DsmLoadingIndicator className="loading-indicator" size="lg"></DsmLoadingIndicator>}</>;
}

export default RequireAuth;
