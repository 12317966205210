import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import "@dsm-dcs/design-system/dist/dsm-design-system/dsm-design-system.css";
import "./index.scss";
import Layout from "./layouts/Layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/other/home/Home";
import Dashboard from "./pages/sampleRequest/dashboard/Dashboard";
import FarmList from "./pages/farm/list/FarmList";
import Error from "./pages/other/error/Error";
import FarmCreate from "./pages/farm/create/FarmCreate";
import { routes } from "./routes";
import RequireAuth from "./components/requireAuth/RequireAuth";
import { Amplify } from "aws-amplify";
import SampleRequestCreate from "./pages/sampleRequest/create/Create";
import SampleRequestList from "./pages/sampleRequest/list/SampleRequestList";
import SampleRequestRead from "./pages/sampleRequest/read/SampleRequestRead";
import KitRequestCreate from "./pages/kitRequest/create/KitRequestCreate";
import OrderHistory from "./pages/kitRequest/orderHistory/OrderHistory";
import { getAwsConfig } from "./helpers/config.helper";
import SSOLogin from "./pages/sso/SSOLogin";
import SSOLogout from "./pages/sso/SSOLogout";
import SSOProxy from "./pages/sso/SSOProxy";
import Support from "./pages/other/support/Support";

Amplify.configure(getAwsConfig());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).digitalData = {
  page: {
    pageInfo: {},
    user: {
      profileInfo: {}
    }
  }
};

//window.print() does not trigger the beforeprint event, so we need to override print and fire the event manually
const print = window.print;
window.print = () => {
  window.dispatchEvent(new Event("beforeprint"));
  setTimeout(() => {
    print();
  }, 0);
};

const router = createBrowserRouter([
  {
    element: <SSOProxy />,
    children: [
      {
        path: "/sso-proxy"
      }
    ]
  },
  {
    element: <SSOLogin />,
    children: [
      {
        path: "/sso-login"
      }
    ]
  },
  {
    element: <SSOLogout />,
    children: [
      {
        path: "/sso-logout"
      }
    ]
  },
  {
    element: <Layout />,
    children: [
      {
        path: "*",
        element: <Error />
      },
      {
        path: routes.home,
        element: (
          <RequireAuth>
            <Home />
          </RequireAuth>
        )
      },
      {
        path: routes.farms,
        element: (
          <RequireAuth>
            <FarmList />
          </RequireAuth>
        )
      },
      {
        path: routes.farmCreate,
        element: (
          <RequireAuth>
            <FarmCreate />
          </RequireAuth>
        )
      },
      {
        path: routes.farmSamples,
        element: (
          <RequireAuth>
            <SampleRequestList />
          </RequireAuth>
        )
      },
      {
        path: routes.farmSample,
        element: (
          <RequireAuth>
            <SampleRequestRead />
          </RequireAuth>
        )
      },
      {
        path: routes.dashboard,
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        )
      },
      {
        path: routes.sampleRequestCreate,
        element: (
          <RequireAuth>
            <SampleRequestCreate />
          </RequireAuth>
        )
      },
      {
        path: routes.order,
        element: (
          <RequireAuth>
            <KitRequestCreate />
          </RequireAuth>
        )
      },
      {
        path: routes.orderStatus,
        element: (
          <RequireAuth>
            <OrderHistory />
          </RequireAuth>
        )
      },
      {
        path: routes.support,
        element: (
          <RequireAuth>
            <Support />
          </RequireAuth>
        )
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
