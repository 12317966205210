import { DsmButton, DsmEmptyState, DsmGrid, DsmIcon, DsmLoadingIndicator, DsmTable } from "@dsm-dcs/design-system-react";
import { DsmTableData, RowActionClickEvent } from "@dsm-dcs/design-system/dist/types/types/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PageHeader from "../../../components/pageHeader/PageHeader";
import styles from "./Home.module.scss";
import { getSampleResultsForCustomer } from "../../../services/sample.service";
import { useLayout } from "../../../contexts/layout.context";
import { useNavigate } from "react-router-dom";
import { routeTypes, routes } from "../../../routes";
import { SampleResultTableInput, KitRequestTableInput, SortDirection } from "../../../models/API";
import { DsmTableCustomEvent } from "@dsm-dcs/design-system";
import { SampleListTableActions } from "../../../models/enums/actions.enum";
import { getKitRequests } from "../../../services/kitRequest.service";
import { AuthContext } from "../../../contexts/auth.context";

function Home() {
  //Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setPageTitle, setCrumbs, setToast } = useLayout();
  const { customer } = useContext(AuthContext);

  //State
  const [resultsTable, setResultsTable] = useState<DsmTableData | null>(null);
  const [ordersTable, setOrdersTable] = useState<DsmTableData | null>(null);

  //Effects
  useEffect(() => {
    setPageTitle(t("home.page.title"));
    setCrumbs([{ title: "", type: routeTypes.home }]);
    initData();
  }, []);

  //Methods
  const initData = async () => {
    const sampleResultTableInput: SampleResultTableInput = { itemsPerPage: 4, sortDirection: SortDirection.Desc };
    const sampleResults = await getSampleResultsForCustomer(customer?.id || "", sampleResultTableInput, setToast);
    const kitRequestTableInput: KitRequestTableInput = { itemsPerPage: 4, sortDirection: SortDirection.Desc };
    const kitRequests = await getKitRequests(customer?.id || "", kitRequestTableInput, setToast);

    setResultsTable({
      columns: [
        { id: "name", label: t("sample-request.name") },
        { id: "collectionDate", label: t("sample-request.collection-date") },
        { id: "phase", label: t("sample-request.phase"), hideFrom: "lg" },
        { id: "average", label: t("sample-request.average") }
      ],
      data:
        sampleResults?.rows?.map((sampleResult) => {
          return {
            isClickable: true,
            id: sampleResult.sampleRequestId || "",
            actions: [
              {
                type: SampleListTableActions.Results,
                icon: "charts/bar-chart-square-01",
                title: t("sample-request-list.table-actions.results")
              }
            ],
            cells: [
              { headerText: sampleResult.name || "" },
              { value: dayjs(sampleResult.collectionDate || "").format(t("general.date-format")) },
              { value: sampleResult.phaseName || "" },
              {
                badges: [
                  {
                    value: sampleResult.average
                      ? `${sampleResult.average?.toString() || ""} ${t("general.d3-unit")}`
                      : t("general.result-status.pending"),
                    variant: sampleResult.average ? "success" : "primary"
                  }
                ]
              }
            ]
          };
        }) || []
    });

    setOrdersTable({
      columns: [
        { id: "farm", label: t("order.farm") },
        { id: "date", label: t("order.date") },
        { id: "status", label: t("order.status") }
      ],
      data:
        kitRequests?.rows?.map((_) => {
          return {
            id: _.kitRequestId || "",
            cells: [
              { value: _.locationName || "" },
              { value: dayjs(_.createdDateTime || "").format(t("general.date-format")) },
              {
                badges: [
                  {
                    value: _.status || "",
                    variant: _.status === "Shipped" ? "primary" : "success"
                  }
                ]
              }
            ]
          };
        }) || []
    });
  };

  const handleRegisterClick = () => {
    navigate(routes.sampleRequestCreate);
  };

  const orderNewKit = () => {
    navigate(routes.order);
  };

  const handleSampleTableRowClick = (e: DsmTableCustomEvent<string>) => {
    navigate(routes.farmSample.replace(":sampleID", e.detail));
  };

  const handleSampleTableAction = (e: DsmTableCustomEvent<RowActionClickEvent>) => {
    if (e.detail.action === SampleListTableActions.Results) {
      navigate(routes.farmSample.replace(":sampleID", e.detail.id));
    }
  };

  return (
    <>
      <div className={styles.hero}>
        <DsmGrid className={styles.hero__content} fixed={true} container-only={true}>
          <h1 className={styles.hero__header}>{t("home.hero.header")}</h1>
          <div className={styles.hero__description}>{t("home.hero.description")}</div>
        </DsmGrid>
      </div>
      <DsmGrid className="main-container" fixed={true} container-only={true}>
        <PageHeader
          header={t("home.header")}
          description={t("home.page.description")}
          videoUrl={t("home.intro-video.url")}
          videoPoster={t("home.intro-video.poster")}
          videoTitle={t("home.intro-video.title")}
        >
          <DsmButton onClick={orderNewKit}>
            {t("home.actions.order")}
            <DsmIcon slot="before" name="finance-ecommerce/shopping-cart-03"></DsmIcon>
          </DsmButton>
          <DsmButton onClick={handleRegisterClick}>
            {t("home.actions.register")}
            <DsmIcon slot="before" name="development/qr-code-02"></DsmIcon>
          </DsmButton>
        </PageHeader>
        <div className={styles.tables}>
          <div className={styles.tables__table}>
            <h3>{t("home.results.header")}</h3>
            {resultsTable ? (
              resultsTable.data.length > 0 ? (
                <DsmTable
                  data-testid="results-table"
                  data={resultsTable}
                  onDsmRowClick={handleSampleTableRowClick}
                  onDsmRowActionClick={handleSampleTableAction}
                ></DsmTable>
              ) : (
                <DsmEmptyState
                  data-testid="results-empty-state"
                  icon="charts/pie-chart-01"
                  header={t("home.results.empty")}
                ></DsmEmptyState>
              )
            ) : (
              <DsmLoadingIndicator></DsmLoadingIndicator>
            )}
          </div>
          <div className={styles.tables__table}>
            <h3>{t("home.orders.header")}</h3>
            {ordersTable ? (
              ordersTable.data.length ? (
                <DsmTable data-testid="orders-table" data={ordersTable}></DsmTable>
              ) : (
                <DsmEmptyState
                  data-testid="orders-empty-state"
                  icon="finance-ecommerce/shopping-cart-03"
                  header={t("home.orders.empty")}
                ></DsmEmptyState>
              )
            ) : (
              <DsmLoadingIndicator></DsmLoadingIndicator>
            )}
          </div>
        </div>
      </DsmGrid>
    </>
  );
}

export default Home;
