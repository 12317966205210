import { DsmAccordion, DsmAccordionItem, DsmGrid, DsmIcon, DsmTabs } from "@dsm-dcs/design-system-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../contexts/layout.context";
import { routeTypes } from "../../../routes";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { IResourceItem } from "../../../models/resourceItem.model";
import styles from "./Support.module.scss";
import { SpeciesGroup } from "../../../models/API";

function Support() {
  //Hooks
  const { t } = useTranslation();
  const { setPageTitle, setCrumbs } = useLayout();

  //State
  const [items, setItems] = useState<IResourceItem[]>([]);
  const [selectedSpecies, setSelectedSpecies] = useState<SpeciesGroup>(SpeciesGroup.Swine);

  //Effects
  useEffect(() => {
    setPageTitle(t("support.page.title"));
    setCrumbs([{ title: t("support.page.title"), type: routeTypes.support }]);
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [selectedSpecies]);

  //Methods
  const handleSpeciesChange = (species: SpeciesGroup) => {
    setSelectedSpecies(species);
  };

  const getItems = () => {
    setItems(t(`support.items.${selectedSpecies.toLocaleLowerCase()}`, { returnObjects: true }));
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader header={t("support.page.title")} description={t("support.page.description")}></PageHeader>
      <DsmTabs className={styles.tabs}>
        {Object.keys(SpeciesGroup).map((speciesGroup) => (
          <a
            slot="navigation"
            className={`${selectedSpecies === speciesGroup ? "active" : ""}`}
            onClick={() => handleSpeciesChange(speciesGroup as SpeciesGroup)}
            key={speciesGroup}
          >
            {t(`general.species.${speciesGroup.toLocaleLowerCase()}`)}
          </a>
        ))}
      </DsmTabs>
      <DsmAccordion>
        {items.length === 0 ? <span>{t("support.no-items")}</span> : null}
        {items.map((item) => (
          <DsmAccordionItem header={item.header} key={item.header}>
            <div className={styles.files}>
              {item.files?.map((file) => (
                <div className={styles.file} key={file.name}>
                  <DsmIcon name="files/file-04"></DsmIcon>
                  <a href={file.url} target="_blank" rel="noreferrer">
                    {file.name}
                  </a>
                  <span>
                    ({file.type} - {file.size})
                  </span>
                </div>
              ))}
            </div>
          </DsmAccordionItem>
        ))}
      </DsmAccordion>
    </DsmGrid>
  );
}

export default Support;
